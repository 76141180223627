import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import './ResellerMonitoringOverview.scss';
import { ReactComponent as ResellerMonitoringImg } from '../../../../assets/images/homeimgs/reseller-monitoring.svg'
import { AiFillCheckCircle, AiOutlineCheck } from 'react-icons/ai'

const ResellerMonitoringOverview = () => {
    return (
        <section className='reseller-monit-container'>
            <div className='reseller-monit-wrapper'>
                <div className='reseller-monit-content'>
                    <div className='reseller-monit-heading'>
                        <h3>Monitoramento de Revendedores</h3>
                    </div>
                    <div className='reseller-monit-text'>
                        <p>
                            Acompanhe os preços dos seus revendedores e verifique se alguém está violando os preços
                            da sua política comercial.
                        </p>
                    </div>
                    <div className='reseller-monit-cards'>
                        <LinkRouter to='/solucoes/monitoramento-de-revendedores'>
                            <div className='card-animation' data-aos='fade-right'>
                                <div className='reseller-monit-card'>
                                    <div className='reseller-monit-icon'>
                                        <AiOutlineCheck size={24} />
                                    </div>
                                    <div className='reseller-monit-span'>
                                        <span>Acompanhe o histórico de preços dos seus revendedores</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-de-revendedores'>
                            <div className='card-animation' data-aos='fade-right'>
                                <div className='reseller-monit-card'>
                                    <div className='reseller-monit-icon'>
                                        <AiOutlineCheck size={24} />
                                    </div>
                                    <div className='reseller-monit-span'>
                                        <span>Crie e acompanhe as ações de contato com os seus revendedores</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-de-revendedores'>
                            <div className='card-animation' data-aos='fade-right'>
                                <div className='reseller-monit-card'>
                                    <div className='reseller-monit-icon'>
                                        <AiOutlineCheck size={24} />
                                    </div>
                                    <div className='reseller-monit-span'>
                                        <span>Visualize em uma única tela os sellers de vários marketplaces que pertencem a uma mesma empresa</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                    </div>
                </div>
                <div data-aos='fade-left'>
                    <ResellerMonitoringImg className='reseller-monit-image' />
                </div>
            </div>
        </section>
    )
}

export default ResellerMonitoringOverview;
