export const DropdownItems = [
    {
        title: 'Monitoramento de Concorrentes',
        path: '/solucoes/monitoramento-de-concorrentes',
        className: 'dropdown-link',
        icon: 'fas fa-chart-line'
    },
    {
        title: 'Monitoramento de Revendedores',
        path: '/solucoes/monitoramento-de-revendedores',
        className: 'dropdown-link',
        icon: 'fas fa-search'
    },
    {
        title: 'Monitoramento Netshoes',
        path: '/solucoes/monitoramento-netshoes',
        className: 'dropdown-link',
        icon: 'fa fa-chart-pie'
    },
    {
        title: 'Precificação Dinâmica',
        path: '/solucoes/precificacao-dinamica',
        className: 'dropdown-link',
        icon: 'fas fa-tags'
    },
    {
        title: 'Soluções Personalizadas',
        path: '/solucoes/solucoes-personalizadas',
        className: 'dropdown-link',
        icon: 'fas fa-cog'
    }
];