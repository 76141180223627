import React from 'react';
import { ReactComponent as Svg } from './svg.svg';
import './Alerts.scss';

const Alerts = () => {
    return (
        <section className='alerts-container'>
            <div className='wrapper'>
                <div className='image' data-aos="fade-right">
                    <Svg />
                </div>
                <div className='content'>
                    <div className='heading'>
                        <h3>Alertas de Precificação</h3>
                    </div>
                    <div className='text'>
                        <p>

                            Configure regras de precificação e receba alertas sempre que surgirem oportunidades para otimizar seus preços. Fique sabendo quando o seu preço estiver abaixo do mínimo, ou acima do máximo,
                            detecte rupturas de estoque e encontre oportunidades para aprimorar sua posição no mercado.

                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Alerts;
