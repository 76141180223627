import React from 'react';
import TopWave from '../../../assets/images/top-wave.svg';
import './blogoverview.scss';
import RecentPosts from './RecentPosts';

const BlogOverview = () => {
    return (
        <section className='blog-container'>
            <div className='blog-wrapper'>
                <div className='blog-title'>
                    <h3>Acesse nosso Blog</h3>
                </div>
                <div className='posts-wrapper'>
                    <RecentPosts />
                </div>
                <button className='blog-button'>
                    <a href='https://hooklab.com.br/blog/' target='_blank'>Ver todos os posts</a>
                </button>
            </div>
        </section>
    )
}

export default BlogOverview;
