import React from 'react';
import useForm from './useForm';
import validateInfo from './validateInfo';
import './ContactForm.scss';

const FormBefore = ({
    submitForm,
    inputColor,
    formTitle,
    formSubtitle,
    formIdentifier
}) => {
    const { handleChange, values, handleSubmit, errors } = useForm(submitForm, validateInfo, formIdentifier);



    const RequiredMessage = ({ error, optional }) => {
        return (
            error
                ? <p>{error}</p>
                : !optional ? <p id='form-label-required' className="form-label-required">Obrigatório*</p> : ``
        )
    }



    /* const handlePhone = (event) => {
        let input = event.target
        input.value = phoneMask(input.value)
    }

    const phoneMask = (value) => {
        if (!value) return ""
        value = value.replace(/\D/g, '')
        value = value.replace(/(\d{2})(\d)/, "($1) $2")
        value = value.replace(/(\d)(\d{4})$/, "$1-$2")
        return value
    } */

    console.log({
        errors
    })

    return (
        <div id="form" className='contact-form-wrapper'>
            <div className='contact-form-heading'>
                {!formTitle ? <h3>Quer ver como funciona na prática?</h3> : <h3>{formTitle}</h3>}
            </div>
            <div className='contact-form-subheading'>
                {!formSubtitle ? <p>Preencha o formulário e nossa equipe entrará em contato para mais informações.</p> : <p>{formSubtitle}</p>}
            </div>
            <form
                className='contact-form'
                onSubmit={handleSubmit}
            >
                <div className='form-inputs-area'>
                    <div className='form-inputs'>
                        <label
                            htmlFor='username'
                            className='form-label'
                        >
                            Nome:
                        </label>
                        <input
                            style={{ backgroundColor: inputColor }}
                            type='text'
                            id='username'
                            name='username'
                            className='required form-input'
                            placeholder='Seu nome'
                            value={values.username}
                            onChange={handleChange}
                        />
                        <RequiredMessage error={errors.username} />
                    </div>
                    <div className='form-inputs'>
                        <label
                            htmlFor='email'
                            className='form-label'
                        >
                            Email:
                        </label>
                        <input
                            style={{ backgroundColor: inputColor }}
                            type='email'
                            id='email'
                            name='email'
                            className='required form-input'
                            placeholder='Seu e-mail'
                            value={values.email}
                            onChange={handleChange}
                        />
                        <RequiredMessage error={errors.email} />

                    </div>
                    <div className='form-inputs'>
                        <label
                            htmlFor='phone'
                            className='form-label'
                        >
                            Telefone:
                        </label>
                        <input
                            style={{ backgroundColor: inputColor }}
                            type='tel'
                            id='phone'
                            name='phone'
                            className='required form-input'
                            placeholder='Seu telefone'
                            value={values.phone}
                            /* onKeyUp={handlePhone} */
                            onChange={handleChange}
                        />
                        <RequiredMessage error={errors.phone} />

                    </div>
                    <div className='form-inputs'>
                        <label
                            htmlFor='company'
                            className='form-label'
                        >
                            Empresa:
                        </label>
                        <input
                            style={{ backgroundColor: inputColor }}
                            type='text'
                            id='company'
                            name='company'
                            className='required form-input'
                            placeholder='Sua Empresa'
                            value={values.company}
                            onChange={handleChange}
                        />
                        <RequiredMessage error={errors.company} />

                    </div>
                    <div className='form-inputs'>
                        <label
                            htmlFor='position'
                            className='form-label'
                        >
                            Cargo:
                        </label>
                        <input
                            style={{ backgroundColor: inputColor }}
                            type='text'
                            id='position'
                            name='position'
                            className='form-input'
                            placeholder='Seu Cargo'
                            value={values.position}
                            onChange={handleChange}
                        />
                        <RequiredMessage optional error={errors.position} />
                    </div>
                    <div className='form-inputs'>

                        <label
                            htmlFor='origin'
                            className='form-label'
                        >
                            Como você conheceu a Hooklab?
                        </label>
                        <select
                            onChange={handleChange}
                            value={values.origin}
                            style={{ backgroundColor: inputColor }}
                            id='origin'
                            name='origin'
                            className='form-input'
                        >
                            <option value='select'>Selecione uma opção</option>
                            <option value='email'>E-mail promocional</option>
                            <option value='google'>Google</option>
                            <option value='indicacao'>Indicação</option>
                            <option value='parceiros'>Parceiros da Hooklab</option>
                            <option value='redes-sociais'>Redes Sociais</option>
                            <option value='blog'>Blog</option>
                            <option value='outros'>Outros</option>
                        </select>
                        <RequiredMessage optional error={errors.origin} />
                    </div>
                    {values.origin === 'outros' &&
                        <div className='form-inputs'>
                            <input
                                style={{ backgroundColor: inputColor }}
                                type='text'
                                id='outros_origin'
                                name='outros_origin'
                                className='required form-input'
                                placeholder='Como você conheceu a Hooklab?'
                                value={values.outros_origin}
                                onChange={handleChange}
                            />
                            <RequiredMessage error={errors.outros_origin} />
                        </div>}
                    <div className='form-inputs'>
                        <label
                            htmlFor='message'
                            className='form-label'
                        >
                            Mensagem:
                        </label>
                        <input
                            style={{ backgroundColor: inputColor }}
                            type='text'
                            id='message'
                            name='message'
                            className='required form-input'
                            placeholder='Sua Mensagem'
                            value={values.message}
                            onChange={handleChange}
                        />
                        <RequiredMessage error={errors.message} />
                    </div>
                    <div className='form-inputs'>
                        <div className='accept-privacy-policy'>
                            <input
                                value={values.privacy}
                                onClick={handleChange}
                                type='checkbox'
                                name='privacy'
                                id='privacy'
                            />
                            <span>Estou de acordo com a <a href="/privacidade" target="_blank">Política de Privacidade</a> da Hooklab</span>
                        </div>
                        <RequiredMessage error={errors.privacy} />
                    </div>
                    <button className='btn btn--form-input' type='submit'>Fale com um de nossos consultores</button>
                </div>
            </form>
        </div>
    )
}

export default FormBefore;
