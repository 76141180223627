import { FiShield } from 'react-icons/fi';
import { RiLightbulbLine, RiHandCoinFill } from 'react-icons//ri';
import { FaRegHandshake } from 'react-icons//fa';

export const values = [
    {
        title: "Responsabilidade",
        description: "A transparência é um dos pilares fundamentais da Hooklab. Acreditamos que a confiança é conquistada através da comunicação clara e aberta com nossos clientes e parceiros. Nos esforçamos para ser transparentes em todas as nossas operações, desde a precificação até a coleta e uso de dados. Nossos clientes têm total visibilidade e controle sobre seus dados, e buscamos sempre garantir que as informações fornecidas por eles estejam protegidas e seguras.",
        icon: <FaRegHandshake />
    },
    {
        title: "Inovação",
        description: "A Hooklab tem um forte compromisso com a inovação e busca constantemente desenvolver soluções cada vez mais eficientes e inteligentes para auxiliar nossos clientes a se destacarem no mercado. Além disso, mantemos um olhar atento às tendências e mudanças do mercado, sempre buscando nos antecipar e oferecer as melhores soluções para nossos clientes.",
        icon: <RiLightbulbLine />
    },
    {
        title: "Segurança",
        description: "Nós sabemos que proteger seus dados é essencial. Por isso, a segurança é uma prioridade absoluta na Hooklab. Implementamos medidas rigorosas e atualizamos regularmente nossos protocolos de segurança para garantir a privacidade e segurança das informações sensíveis e privadas dos nossos clientes.",
        icon: <FiShield />
    },
]