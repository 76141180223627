import "./Numbers.scss";

export default function Numbers() {
    return (
        <section className='numbers-container'>
            <div data-aos='fade-down' className='numbers-container-wrapper'>
                <div className='numbers-item'>
                    <h3>+100MI</h3>
                    <span>Ofertas monitoradas</span>
                </div>
                <div className='numbers-item'>
                    <h3>+200</h3>
                    <span>Sites monitorados</span>
                </div>
                <div className='numbers-item'>
                    <h3>+100MIL</h3>
                    <span>Anúncios precificados</span>
                </div>

            </div>
        </section>
    )
}