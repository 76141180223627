import React, { useState } from 'react';
import FormBefore from './FormBefore';
import './DemoForm.scss';
import FormSuccess from './FormSuccess';

const DemoForm = () => {

    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }

    return (
        <section>
            {!isSubmitted ? (<FormBefore submitForm={submitForm} />) : (<FormSuccess />)}
        </section>

    )
}

export default DemoForm;