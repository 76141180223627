import { GiConverseShoe, GiMagnifyingGlass, GiTechnoHeart } from 'react-icons/gi';
import { ReactComponent as CustomSolutionsMainImg } from '../../../assets/images/solucoespersonalizadas/custom-solutions-main.svg';

import { BsBoxes, BsRocketTakeoff, BsShop, BsTrophy } from 'react-icons/bs'
import { ReactComponent as ConcorrentImg } from '../../../assets/images/monitoramentoprecos/img1.svg';

import { FiBarChart } from 'react-icons/fi';

const height = "300px"

export const solutions = [
    {
        title: "O Início da Hooklab",
        description: "Nós fundamos a Hooklab com o intuito de trabalhar em soluções de inteligência de preços, com um foco inicial em monitoramento de preços para calçados na plataforma Netshoes. Durante esse período, nós desenvolvemos uma sólida compreensão do mercado de e-commerce e identificamos uma necessidade crescente de soluções de inteligência de preços mais avançadas e abrangentes.",
        img: <ConcorrentImg height={height} />,
        icon: <BsShop size={40} />,
        href: "/solucoes/monitoramento-de-concorrentes",
        date: "Junho, 2019"
    },
    {
        title: "Lançamento da Hooklab",
        description: "Lançamos a plataforma Hooklab oferecendo diversas soluções para aumentar a performance dos lojistas que vendiam na Netshoes, como o monitoramento de preços de concorrentes, competitividade dos sellers e alteração de preços em massa",
        img: null,
        icon: <GiConverseShoe size={40} />,
        href: "/solucoes/monitoramento-netshoes",
        date: "Janeiro, 2020"
    },
    {
        title: "Atingimos a marca de 100 mil ofertas monitoradas",
        description: `Nesse período, alcançamos um marco histórico ao monitorar 
        simultaneamente mais de 100.000 anúncios ofertados na Netshoes. 
      
        Esse marco nos motivou a continuar desenvolvendo nossas soluções, buscando sempre melhorar a performance de quem vende online.
        `,
        img: null,
        icon: <BsBoxes size={40} />,
        href: "/solucoes/monitoramento-netshoes",
        date: "Setembro, 2020"
    },
    {
        title: "Novos produtos e mercados",
        description: `Com objetivo de expandir a nossa operação, desenvolvemos uma nova solução de monitoramento de preços para atender os principais marketplaces e e-commerces brasileiros. Além disso, também lançamos a solução de precificação dinâmica, buscando trazer mais eficiência para quem deseja se posicionar no mercado.
        `,
        img: <BsRocketTakeoff height={height} />,
        icon: <BsRocketTakeoff size={40} />,
        href: "/solucoes/precificacao-dinamica",
        date: "Março, 2021"
    },
    {
        title: "Monitoramento de Revendedores",
        description: "Considerando a importância de auxiliar grandes marcas em suas estratégias comerciais, desenvolvemos uma solução de monitoramento de revendedores. Isso permitiu que essas empresas acompanhassem como seus produtos eram precificados pelos revendedores e se sua política comercial estava de acordo com o mercado.",
        img: <CustomSolutionsMainImg height={height} />,
        icon: <GiMagnifyingGlass size={40} />,
        href: "/solucoes/solucoes-personalizadas",
        date: "Setembro, 2021"
    },
    {
        title: "Conquistamos o terceiro lugar no bootcamp Miditec",
        description: "Participamos do bootcamp da Miditec, uma das maiores incubadoras do Brasil, finalizando em terceiro lugar dentro dos 20 participantes. Esse foi um grande marco para a empresa, permitindo que a Hooklab ganhasse grande visibilidade no mercado. ",
        img: <CustomSolutionsMainImg height={height} />,
        icon: <BsTrophy size={40} />,
        href: "/solucoes/solucoes-personalizadas",
        date: "Janeiro, 2022"
    },
    {
        title: "Entramos na vertical de varejo da ACATE",
        description: `
        Fomos convidados a integrar a vertical de varejo da ACATE, um dos maiores ecossistemas de tecnologia do país, que reúne empresas inovadoras e de destaque em suas áreas de atuação. Com essa nova parceria, tivemos a oportunidade de expandir a nossa rede de contatos e trocar experiências com outros players do mercado, além de contribuir para o desenvolvimento do setor varejista através das nossos produtos.
`,
        img: <CustomSolutionsMainImg height={height} />,
        icon: <GiTechnoHeart size={40} />,
        href: "/solucoes/solucoes-personalizadas",
        date: "Agosto, 2022"
    },
    {
        title: "Crescemos 200%",
        description: `Encerramos o ano de 2022 com um aumento de 200% no número de clientes. Esse resultado foi fruto do aprimoramento dos nossos produtos, e também à dedicação de toda a nossa equipe em proporcionar uma experiência de qualidade para os nossos clientes.
        `,
        img: <CustomSolutionsMainImg height={height} />,
        icon: <FiBarChart size={40} />,
        href: "/solucoes/solucoes-personalizadas",
        date: "Dezembro, 2022"
    },
]