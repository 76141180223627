export const DropdownLoginItems = [
    {
        title: 'Hooklab Monitoramento',
        path: 'https://monitoramento.hooklab.com.br/app/login',
        className: 'dropdown-link',
        icon: 'fas fa-sign-in-alt',
        spanClass: 'login-hooklab'
    },
    {
        title: 'Monitoramento Netshoes',
        path: 'https://hooklab.com.br/pt-br/login',
        className: 'dropdown-link',
        icon: 'fas fa-sign-in-alt',
        spanClass: 'login-netshoes'
    }
];