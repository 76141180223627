import styles from './Solution.module.scss';

function SolutionSection({ solution }) {



    return (
        <section className={styles.container}>

            <h4>{solution.date}</h4>
            <h3>{solution.title}</h3>
            <p>{solution.description}</p>
        </section >
    )
}

export default SolutionSection;