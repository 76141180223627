import React, { useState } from 'react';
import { Link as LinkRouter, useLocation, useHistory } from 'react-router-dom';
import { Link as LinkScroll, animateScroll as scroll } from 'react-scroll';
import './navbar.scss';
import hookLogo from '../../assets/images/hook-icon.png';
import { Button } from './Button.js';
import { Dropdown } from './Dropdown.js';
import { DropdownLogin } from './DropdownLogin';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5"

const Navbar = () => {

    const currentRoute = useHistory().location.pathname.toLowerCase();

    const location = useLocation()

    const [click, setClick] = useState(false);

    const [dropdown, setDropdown] = useState(false);

    const [dropdownLogin, setDropdownLogin] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    const onMouseEnter2 = () => {
        if (window.innerWidth < 960) {
            setDropdownLogin(false);
        } else {
            setDropdownLogin(true);
        }
    };

    const onMouseLeave2 = () => {
        if (window.innerWidth < 960) {
            setDropdownLogin(false);
        } else {
            setDropdownLogin(false);
        }
    };

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const navigateHome = () => {
        const currentUrl = location.pathname
        if (currentUrl === '/') {
            scroll.scrollToTop();
        }
        closeMobileMenu();

    }

    return (
        <>
            <nav className='navbar'>
                <LinkRouter to='/' className='navbar-logo' onClick={toggleHome}>
                    hook<span className='blue'>lab</span>
                </LinkRouter>
                <div className='menu-icon' onClick={handleClick}>
                    {click ? <IoCloseSharp /> : <GiHamburgerMenu />}
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                    <li className='nav-item display-none-mobile' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <a className={currentRoute.includes('solucoes') ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Soluções <i className='fas fa-caret-down' />
                        </a>

                        {dropdown && <Dropdown />}
                    </li>
                    <li className='nav-item display-only-mobile'>
                        <LinkRouter to='/solucoes/monitoramento-de-concorrentes' className='nav-links' onClick={closeMobileMenu}>
                            Monitoramento de Concorrentes
                        </LinkRouter>
                    </li>
                    <li className='nav-item display-only-mobile'>
                        <LinkRouter to='/solucoes/monitoramento-de-revendedores' className='nav-links' onClick={closeMobileMenu}>
                            Monitoramento de Revendedores
                        </LinkRouter>
                    </li>
                    <li className='nav-item display-only-mobile'>
                        <LinkRouter to='/solucoes/monitoramento-netshoes' className='nav-links' onClick={closeMobileMenu}>
                            Monitoramento Netshoes
                        </LinkRouter>
                    </li>
                    <li className='nav-item display-only-mobile'>
                        <LinkRouter to='/solucoes/precificacao-dinamica' className='nav-links' onClick={closeMobileMenu}>
                            Precificação Dinâmica
                        </LinkRouter>
                    </li>
                    <li className='nav-item display-only-mobile'>
                        <LinkRouter to='/solucoes/solucoes-personalizadas' className='nav-links' onClick={closeMobileMenu}>
                            Soluções Personalizadas
                        </LinkRouter>
                    </li>
                    <li className='nav-item'>
                        <LinkRouter to='/planos' className={currentRoute.includes('planos') ? 'nav-links active' : 'nav-links'} onClick={closeMobileMenu}>
                            Planos
                        </LinkRouter>
                    </li>
                    <li className='nav-item'>
                        <LinkRouter to='/sobre' className='nav-links' smooth={true} spy={true} exact='true' offset={-80}
                            onClick={navigateHome}>
                            Empresa
                        </LinkRouter>
                    </li>
                    <li className='nav-item'>
                        <a href='https://hooklab.com.br/blog/' target='_blank' className='nav-links' onClick={closeMobileMenu}>
                            Blog
                        </a>
                    </li>
                    <li className='nav-item display-none-mobile nav-right' onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}>
                        <LinkRouter className='nav-links' onClick={closeMobileMenu}>
                            Entrar
                        </LinkRouter>

                        {dropdownLogin && <DropdownLogin />}
                    </li>
                    <div onClick={closeMobileMenu}>
                        <Button />
                    </div>
                </ul>
            </nav>
        </>
    )
}

export default Navbar;
