import React from 'react';
import ResellerPlansImg from '../../../assets/images/monitoramentorevendedores/reseller-plans-img.svg';
import PricingButton from '../../PricingButton';
import './ResellerPlans.scss';

const ResellerPlans = () => {
    return (
        <section className='reseller-plans-container'>
            <div className='reseller-plans-wrapper'>
                <div className='reseller-plans-content'>
                    <div className='reseller-plans-heading'>
                        <h3>Confira nossos preços!</h3>
                    </div>
                    <div className='reseller-plans-text'>
                        <p>
                            Deseja conferir nossos preços e conversar com um de nossos especialistas para ver qual plano mais 
                            se adequa à sua demanda?
                        </p>
                    </div>
                    <PricingButton />
                </div>
                <div className='reseller-plans-image' data-aos="fade-up">
                    <img src={ResellerPlansImg} ResellerPlansImg />
                </div>
            </div>
        </section>
    )
}

export default ResellerPlans;
