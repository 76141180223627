import React from 'react'
import './privacy-container.styles.scss'

export default function PrivacyContainer() {
    return (

        <div id='privacy-container'>
            <h1>Política de privacidade da Hooklab</h1>
            <p>
                Nós, da Hooklab, estamos comprometidos em resguardar sua privacidade e proteger seus dados. O intuito deste documento é esclarecer quais informações são coletadas dos usuários de nosso site - https://hooklab.com.br/ – e respectivos serviços – e de que forma esses dados são utilizados.
            </p>
            <h3>1. Compartilhamento de dados de usuários com nossos sites</h3>
            <p>
                Todos os dados inseridos em formulários de nossas páginas sob nosso domínio - <a href="http://www.hooklab.com">hooklab.com.br</a> - serão enviados de forma segura aos nossos bancos de dados instalados em servidores protegidos. Nenhuma informação inserida em nosso site será divulgada ou compartilhada com terceiros fora da equipe da Hooklab, exceto nos casos de requerimento de autoridades judiciais ou governamentais competentes para fins de investigações pessoais conduzidas por elas.
            </p>
            <p>
                A Hooklab realiza ações promocionais oferecendo conteúdos didáticos gratuitos. O acesso a esses conteúdos é permitido a partir de uma inscrição, onde o usuário fornece seus dados pessoais para contato (nome, e-mail, telefone) e dados profissionais (empresa onde trabalha, cargo de atuação, segmento, porte da empresa, HUB de Integração/ERP, etc.), que serão armazenados em nossa base de dados e na base de dados de parceiros de forma segura a partir do domínio hooklab.com.br.
            </p>
            <p>
                Em nossas ações promocionais, a Hooklab nunca requisitará dados como: número de identidade, número de cartão de crédito, número de conta bancária, CPF, CNH e outros dados sensíveis definidos nos termos da LGPD (Lei Geral de Proteção de Dados).
            </p>
            <p>
                Ao realizar a inscrição, o usuário concorda em ter seus dados armazenados e também é oferecida a possibilidade de receber comunicações personalizadas baseadas em seus interesses. O usuário pode se recusar a receber novas mensagens a qualquer hora, assim como requerer o apagamento total desses dados em nossa base.
            </p>
            <h3>2. Informações coletadas automaticamente</h3>
            <p>
                Caso você apenas acesse os nossos sites, coletamos seus dados de navegação. No caso, quando você visita o nosso site é inserido um “cookie” no seu navegador por meio do software Google Analytics, para identificar quantas vezes você retorna ao nosso endereço.
            </p>
            <p>
                “Cookies” são identificadores que transferimos para o seu navegador ou dispositivo que nos permitem reconhecer seu navegador ou dispositivo e nos informam como e quando as páginas e recursos em nossos Serviços são visitados e quantas pessoas as acessam.
            </p>
            <p>
                Também usamos Cookies e dados de navegação, como URLs (Uniform Resource Locators) para coletar informações sobre a data e a hora da sua visita e as soluções e informações para as quais você pesquisou e visualizou. Como a maioria das empresas de tecnologia, coletamos automaticamente esses dados pessoais e os armazenamos em arquivos de registro sempre que você visita nosso site ou acessa sua conta em nossa rede. Podemos usar cookies de sessão (que expiram depois de fechar o navegador) e cookies persistentes (que permanecem no seu computador até que você os exclua) para fornecer uma experiência mais pessoal e interativa em nosso site. Os Cookies Persistentes podem ser removidos seguindo as instruções do arquivo de ajuda do seu navegador da Internet. Você pode optar por limitar, recusar ou desabilitar os Cookies através das configurações do seu navegador. Contudo, ao fazer isso, algumas áreas do nosso site podem não funcionar corretamente, o que poderá impedir que você se beneficie de alguns de nossos recursos.
            </p>
            <p>
                Novamente, esta Política de Privacidade não cobre o uso de Cookies por terceiros, e não somos responsáveis ​​por suas políticas e práticas de privacidade. Tenha em atenção que os Cookies colocados por terceiros podem eventualmente continuar a monitorizar as suas atividades online mesmo depois de ter saído dos nossos Serviços.
            </p>
            <h3>3. Informações de parceiros comerciais da Hooklab</h3>
            <p>
                A Hooklab conta com parceiros comerciais como empresas fornecedoras de software de prospecção digital, empresas fornecedoras de softwares de gestão empresarial, consultores e mentores. Nós podemos utilizar esses dados e os juntar com os de base da Hooklab, sendo que todos esses dados serão tratados como dados pessoais.
            </p>
            <h3>Com quem nós compartilhamos os dados</h3>
            <p>
                Apenas usuários da empresa contratante poderão ter acesso aos dados. No caso da necessidade por parte de nossos especialistas em acessar dados dos nossos clientes para suporte e manutenção, isso somente será feito com a devida autorização dos detentores dos dados.
            </p>
            <h3>Informações adicionais</h3>
            <p>
                Nunca enviamos e-mails ou ligamos pedindo aos nossos usuários informações sobre senha de acesso ao sistema ou informações sobre cartão de crédito ou qualquer outro meio de pagamento.
            </p>
        </div>



    )
}
