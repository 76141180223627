import React from 'react';
import './DemoForm.scss';
import PlansMsgImg from '../../../assets/images/planos/plans-sent-message.svg';

const FormSuccess = () => {
    return (
        <div className='demo-success-msg-wrapper'>
            <div className='demo-success-msg-image'>
                <img src={PlansMsgImg} />
            </div>
            <div className='demo-success-msg-text'>
                <p>
                    Sua solicitação foi enviada e logo entraremos em contato!
                </p>
            </div>
        </div>
    )
}

export default FormSuccess;
