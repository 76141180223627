import React from 'react';
import NotFound from '../components/NotFound/NotFound';

const PageNotFound = () => {
    return (
        <>
            <NotFound />
        </>
    )
}

export default PageNotFound;
