import React, { useState } from 'react';
import { Link as LinkScroll } from 'react-scroll';
import { BiPlay } from 'react-icons/bi'
import VideoModal from '../Demonstracao/VideoModal';
import './problems-button.styles.scss';

const ProblemsButton = ({ scrollTo }) => {

    const [viewVideo, setViewVideo] = useState(false);



    return (
        <>
            <div className='problems-button'>
                {/* <button onClick={() => setViewVideo(true)} className='black-btn'>
                    <BiPlay />
                    Assista ao vídeo promocional
                </button> */}
                <LinkScroll to={scrollTo} smooth={true} spy={true} exact='true' offset={-80}>
                    <button>Veja como a Hooklab pode te ajudar</button>
                </LinkScroll>
            </div>
            {viewVideo && <VideoModal view={viewVideo} setView={setViewVideo} videoURL="https://www.youtube.com/embed/Mh7tWtGCpz4" />}
        </>
    )
};

export default ProblemsButton;