import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundImg } from '../../assets/images/notfound/404.svg';
import './NotFound.scss';

const NotFound = () => {
    return (
        <div className='not-found-container'>
            <div className='not-found-wrapper'>
                <NotFoundImg className='not-found-image' />
                <div className='not-found-message'>
                    <h1>Oops... essa página não existe!</h1>
                    <h2>Mas você pode tentar um dos links abaixo:</h2>
                </div>
                <div className='not-found-links'>
                    <div className='not-found-link'>
                        <Link to='/'>Home</Link>
                        <Link to='/planos'>Planos</Link>
                        <a href='https://hooklab.com.br/blog/'>Blog</a>
                        <Link to='/demonstracao'>Demonstração Gratuita</Link>
                    </div>
                    <div className='not-found-link'>
                        <Link to='/solucoes/monitoramento-de-concorrentes'>Monitoramento de Concorrentes</Link>
                        <Link to='/solucoes/monitoramento-de-revendedores'>Monitoramento de Revendedores</Link>
                        <Link to='/solucoes/monitoramento-netshoes'>Monitoramento Netshoes</Link>
                        <Link to='/solucoes/precificacao-dinamica'>Precificação Dinâmica</Link>
                        <Link to='/solucoes/solucoes-personalizadas'>Soluções Personalizadas</Link>
                    </div>
                    <div className='not-found-link'>
                        <a href='https://monitoramento.hooklab.com.br/app/login'>Entrar em Hooklab Monitoramento</a>
                        <a href='https://hooklab.com.br/pt-br/login/'>Entrar em Monitoramento Netshoes</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
