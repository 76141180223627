import React from 'react';
import {ReactComponent as Problems1} from '../../../assets/images/precificacaodinamica/pricing-problems-1.svg';
import {ReactComponent as Problems2} from '../../../assets/images/precificacaodinamica/pricing-problems-2.svg';
import {ReactComponent as Problems3} from '../../../assets/images/precificacaodinamica/pricing-problems-3.svg';
import {ReactComponent as Problems4} from '../../../assets/images/precificacaodinamica/pricing-problems-4.svg';
import ProblemsButton from '../../ProblemsButton';
import './PricingProblems.scss';

const PricingProblems = () => {
    return (
        <section className='pricing-problems-container'>
            <div className='pricing-problems-wrapper'>
                <div className='pricing-problems-heading'>
                    <h3>Você lida com alguma dessas situações?</h3>
                </div>
                <div className='pricing-problems-area'>
                    <div className='pricing-problems-items'>
                        <div className='pricing-problems-img' data-aos="fade-down">
                            <Problems1 />
                        </div>
                        <div className='pricing-problems-text' data-aos="fade-up">
                            <p>
                                Gasto muito tempo alterando os preços dos meus produtos manualmente
                            </p>
                        </div>
                    </div>
                    <div className='pricing-problems-items'>
                        <div className='pricing-problems-img coins' data-aos="fade-down">
                            <Problems2 />
                        </div>
                        <div className='pricing-problems-text' data-aos="fade-up">
                            <p>
                                Estou em uma guerra de preços com alguns concorrentes
                            </p>
                        </div>
                    </div>
                    <div className='pricing-problems-items'>
                        <div className='pricing-problems-img' data-aos="fade-down">
                            <Problems3 />
                        </div>
                        <div className='pricing-problems-text' data-aos="fade-up">
                            <p>
                                Quero automatizar o processo de precificação para ganhar eficiência e inteligência
                            </p>
                        </div>
                    </div>
                    <div className='pricing-problems-items'>
                        <div className='pricing-problems-img' data-aos="fade-down">
                            <Problems4 />
                        </div>
                        <div className='pricing-problems-text' data-aos="fade-up">
                            <p>
                                Quero construir estratégia comercial para posicionar meus preços
                            </p>
                        </div>
                    </div>
                </div>
                <ProblemsButton scrollTo='pricing-rules' />
            </div>
        </section>
    )
}

export default PricingProblems;
