import '../Home/About/about.scss'
import "../Home/ProductsOverview/ResellerMonitoringOverview/ResellerMonitoringOverview.scss"
import Main from './Main';
import About from './About';
import Numbers from './Numbers';
import Values from './Values';
import BlogOverview from '../Home/BlogOverview';
import TimeLine from './Timeline';


export default function NewAbout() {

    return (
        <>
            <Main />
            <About />
            <Numbers />
            <Values />
            {/* <Brands /> */}
            <TimeLine />
            <BlogOverview  />
        </>
    )
}