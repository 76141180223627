export const Questions = [
    {
        question: 'O que é precificação dinâmica?',
        answer: ['Com a precificação dinâmica, você precifica seus produtos de forma automatizada frente as movimentações realizadas pelos concorrentes. Para garantir que seus preços sejam ajustados corretamente e considerando diversos fatores que podem afetar o mercado, a Hooklab permite que sua empresa se posicione de forma estratégica e eficiente, aproveitando as oportunidades de mercado.']
    },
    {
        question: 'Posso confiar meus preços na Hooklab?',
        answer: ['A Hooklab possui soluções avançadas de precificação para otimizar os preços de seus produtos, possibilitando a alteração de preços diretamente na sua plataforma e-commerce e integrador com os marketplaces, garantindo ao nosso cliente margem de lucro e competitividade dentro de cada canal de venda.']
    },
    {
        question: 'Como funciona a precificação dinâmica?',
        answer: ['A precificação dinâmica da Hooklab funciona de forma totalmente automática, garantindo a atualização dos preço de seus anúncios através de integração própria com seu HUB ou integrador. A plataforma gerencia o preço de cada um dos seus anúncios de acordo com regras de posicionamento de preços em relação aos concorrentes.', 
        <br/>,
        'Junto com a definição de limites de preços mínimo e máximo, você pode garantir um controle preciso sobre o preço de cada produto ou anúncio, assegurando que sua estratégia de precificação seja tanto estratégica quanto eficiente. Aproveitando as oportunidades do mercado, mantendo sua margem de lucro e garantindo a competitividade do seu negócio. Além disso, oferecemos recursos para precificação com base no seu estoque e em casos de ruptura de produtos.']
    },
    {
        question: 'Como funciona a integração?',
        answer: ['A integração é realizada através de API do seu HUB ou integrador com a Hooklab, permitindo que a alteração de preços seja sempre feita de forma automática.']
    }
];