import React from 'react';
import {ReactComponent as OurPricesImg} from '../../../assets/images/monitoramentonetshoes/our-prices.svg';
import PricingButton from '../../PricingButton';
import './NetshoesPlans.scss';

const NetshoesPlans = () => {
    return (
        <section className='our-prices-container'>
            <div className='our-prices-wrapper'>
                <div className='our-prices-content'>
                    <div className='our-prices-heading'>
                        <h3>Confira nossos preços!</h3>
                    </div>
                    <div className='our-prices-text'>
                        <p>
                            Deseja conferir nossos preços e conversar com um de nossos especialistas para ver qual plano mais 
                            se adequa à sua demanda?
                        </p>
                    </div>
                    <PricingButton />
                </div>
                <div className='our-prices-image' data-aos="fade-up">
                    <OurPricesImg />
                </div>
            </div>
        </section>
    )
}

export default NetshoesPlans;
