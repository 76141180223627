import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import './NetshoesOverview.scss';
import { ReactComponent as NetshoesOverviewImg } from '../../../../assets/images/homeimgs/netshoes-overview.svg';
import StarNetshoesImg from '../../../../assets/images/homeimgs/star.png';
import ZoomNetshoesImg from '../../../../assets/images/homeimgs/zoom.png';
import ChartNetshoesImg from '../../../../assets/images/homeimgs/chart2.png';
import PriceNetshoesImg from '../../../../assets/images/homeimgs/price-tag.png';
import { AiOutlineSearch, AiOutlineTags, AiOutlineTrophy } from 'react-icons/ai';
import { BiBarChart } from 'react-icons/bi';

const NetshoesOverview = () => {
    return (
        <section className='netshoes-overview-container'>
            <div className='netshoes-overview-wrapper'>
                <div data-aos='fade-right'>
                    <NetshoesOverviewImg className='netshoes-overview-image' />
                </div>
                <div className='netshoes-overview-content'>
                    <div className='netshoes-overview-heading'>
                        <h3>Monitoramento Buy-Box Netshoes</h3>
                    </div>
                    <div className='netshoes-overview-text'>
                        <p>
                            Você vende na Netshoes? Temos uma solução especializada nesse canal que vai te ajudar a vender mais monitorando os sellers que competem com você no buybox e mostrando como precificar cada oferta.
                        </p>
                    </div>
                    <div className='netshoes-overview-cards'>
                        <LinkRouter to='/solucoes/monitoramento-netshoes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='netshoes-overview-card'>
                                    <div className='netshoes-overview-icon'>
                                        <AiOutlineTrophy size={40} />
                                    </div>
                                    <div className='netshoes-overview-span'>
                                        <span>Domine o buy-box</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-netshoes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='netshoes-overview-card'>
                                    <div className='netshoes-overview-icon'>
                                        <AiOutlineSearch size={40} />
                                    </div>
                                    <div className='netshoes-overview-span'>
                                        <span>Conheça a concorrência</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-netshoes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='netshoes-overview-card'>
                                    <div className='netshoes-overview-icon'>
                                        <BiBarChart size={40} />
                                    </div>
                                    <div className='netshoes-overview-span'>
                                        <span>Melhore sua performance</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-netshoes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='netshoes-overview-card'>
                                    <div className='netshoes-overview-icon'>
                                        <AiOutlineTags size={40} />
                                    </div>
                                    <div className='netshoes-overview-span'>
                                        <span>Altere preços em massa</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NetshoesOverview;
