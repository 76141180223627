import "./DynamicPrecification.scss"
import InputLenght from "../InputLenght/inputLength"
import { useLayoutEffect, useState } from "react"
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai"
import { Link } from "react-router-dom"
import ContactForm from "../../../ContactForm"

export default function DynamicPrecification({ view, handleClose }) {

    const [active, setActive] = useState(false)

    useLayoutEffect(() => {
        view === "dynamic" ? setActive(true) : setActive(false)
        console.log(view.view);
        window.scrollBy(0, 0)
    }, [view])

    return (
        active ?
            <section >
                <div id="solution" className="container container--solution">
                    <div className="solution-main-wrapper">
                        <div className="heading-secondary heading-secondary--main">
                            <h3>Precificação Dinâmica</h3>

                        </div>
                        <div className="heading-secondary--sub">
                            <p>Automatize a sua precificação</p>
                        </div>
                        <div className="solution solution--1"> 
                            <div className="solution__heading">
                                <h4 className="solution__heading--title">Benefícios da Solução</h4>
                            </div>
                            <div className="solution__boding">
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Automatize a precificação</h4>
                                        <p className="heading-tertiary--sub">Você poderá automatizar a sua precificação a partir de regras de precificação personalizadas</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div className="solution__boding--row--content">
                                        <h4 className="heading-tertiary--main">Monitoramento de preços de concorrentes</h4>
                                        <p className="heading-tertiary--sub">Você terá a possibilidade de monitorar os anúncios dos seus concorrentes e entender como seus produtos estão posicionados no mercado</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div className="solution__boding--row--content">
                                        <h4 className="heading-tertiary--main">Matching de produtos</h4>
                                        <p className="heading-tertiary--sub">A Hooklab irá encontrar os produtos de concorrentes que estão competindo diretamente com você</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div className="solution__boding--row--content">
                                        <h4 className="heading-tertiary--main">Histórico de preços</h4>
                                        <p className="heading-tertiary--sub">Você terá acesso ao histórico completo de todas as ofertas que estiverem sendo monitoradas pela Hooklab</p>
                                    </div>
                                </div>


                                <div
                                    className="solution__boding--row solution__boding--row--center"
                                >
                                    <Link
                                        to='/solucoes/precificacao-dinamica'
                                        className='btn--link solution__boding--row--center__button'
                                    >
                                        <AiOutlinePlus
                                            style={{ marginRight: 5 }}
                                        />
                                        Saiba mais sobre a solução</Link>
                                </div>
                                <InputLenght
                                    view={view}
                                    skuPrice={4}
                                    channelPrice={2.4}
                                    title='Para quantos produtos do seu catálogo, você deseja automatizar a precificação?'
                                />
                                <div 
                                    className="btn--link-wrapper" 
                                >
                                    <button
                                        className='btn--link'
                                        onClick={handleClose}
                                    >
                                        Escolher outra solução
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactForm formTitle='Fale com um dos nossos consultores' formIdentifier='planos-precificacao-dinamica' bgColor='#fff' inputColor='#F0F5F8' />
                  
            </section>
            : null
    )
}