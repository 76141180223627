export const Questions = [
    {
        question: 'Como funciona o monitoramento de preços?',
        answer: ['O monitoramento de preço acontece em anúncios disponibilizados nos principais canais de e-commerces e marketplaces. O algoritmo extrai informações de todas as ofertas relacionadas ao produto monitorado em intervalos de tempo. Os dados obtidos são convertidos em informações na plataforma Hooklab.']
    },
    {
        question: 'Como minha empresa se beneficia de uma ferramenta de monitoramento de preços?',
        answer: ['Reunir informações manualmente não é vantajoso. Ter uma ferramenta que agrega todas as informações e retira esse processo contra-produtivo da sua rotina de trabalho, faz com que você pare de gastar seu tempo monitorando link por link e comece a analisar indicadores para decidir quais decisões tomar.']
    },
    {
        question: 'Como cadastrar meus produtos na plataforma?',
        answer: ['Cadastre seus produtos manualmente na plataforma ou em massa através de planilhas. Ou, integre facilmente nossa plataforma com o seu sistema de gerenciamento, como ERP ou HUB, e mantenha as informações sincronizadas de forma automática.']
    },
    {
        question: 'Com qual frequência o monitoramento de preços é feito?',
        answer: [
            "Ao contratar as nossas soluções:  Monitoramento Concorrentes, Precificação dinâmica e Monitoramento de Revendedores disponibilizamos as seguintes opções de frequências.",             
            "**Frequência Fixa:**", 
            "O monitoramento dos anúncios na frequência fixa, poderá ocorrer a cada (1h, 2h, 4h até 24h) sempre respeitando o que for acordado no momento da contratação da solução.", 
            "**Frequência Dinâmica**:", 
            "Na frequência dinâmica o algoritmo de leitura acompanhará a movimentação de preço de cada uma das ofertas (URL) para definir a frequência necessária de acompanhamento, o  foco é ter alta rodagem de leituras em ofertas que possuam uma alta movimentação de preço.", 
            "De uma forma mais clara ilustramos com seguintes exemplos:", 
            "Para um anúncio que ocorreu alteração do preço 5x ao dia, esse anúncio será monitorado durante as 5x.", 
            "E menor frequência com mínimo de 24h, nas ofertas que alterem de preço em momento específicos.", 
            "Ex: Anúncios que sofrem alteração 1x ao mês, esse anúncio será monitorado na frequência de 24h." 
        ]
    },
    {
        question: 'Qual o diferencial da Hooklab em relação a outras ferramentas de monitoramento de preço?',
        answer: ['A Hooklab oferece monitoramento de dados e análise avançada para ajudar sua empresa a tomar decisões informadas. Nossa plataforma transforma dados web em informações relevantes e fáceis de interpretar, economizando o seu tempo e otimizando sua produtividade. Com a Hooklab, você tem acesso às informações que realmente importam para sua análise, sem a necessidade de extrair dados manualmente. Além disso, a plataforma é fácil de usar e é possível integrar com outros sistemas. Nos contate para obter vantagem competitiva no mercado, baseado em dados precisos e atualizados.']
    }

];