import React from 'react';
import {ReactComponent as YourCompetitorsImg} from '../../../assets/images/monitoramentonetshoes/your-competitors.svg';
import './YourCompetitors.scss';

const YourCompetitors = () => {
    return (
        <section className='your-competitors-container'>
            <div className='your-competitors-wrapper'>
                <div className='your-competitors-content'>
                    <div className='your-competitors-heading'>
                        <h3>Saiba quem são seus concorrentes para cada produto</h3>
                    </div>
                    <div className='your-competitors-text'>
                        <p>
                            Através da Hooklab você conhece todos os seus concorrentes para cada produto que vende. 
                            Identifique padrões e monte estratégias de precificação para melhorar seu posicionamento de preço.
                        </p>
                    </div>
                </div>
                <div className='your-competitors-image' data-aos="fade-left">
                    <YourCompetitorsImg />
                </div>
            </div>
        </section>
    )
}

export default YourCompetitors;
