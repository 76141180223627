import Typewriter from 'typewriter-effect';
import { Link as LinkRouter } from 'react-router-dom';
import { ReactComponent as MainSvg } from '../../../assets/images/homeimgs/main-sec-svg.svg';
import ResellerMonitIcon from '../../../assets/images/homeimgs/check.png';

export default function Main() {
    return (
        <section className='main-container'>
            <div className='main-wrapper'>
                <div className='main-content'>
                    <div className='main-heading'>
                        <h1>Desenvolvemos tecnologia para tornar a precificação mais fácil</h1>
                    </div>
                    <div className='dynamic-texts'>
                        <Typewriter
                            options={{
                                strings: ['Monitoramento de Concorrentes', 'Monitoramento de Revendedores',
                                    'Monitoramento Netshoes', 'Precificação Dinâmica'],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </div>
                    <div className='main-subheading'>
                        <p>Eleve sua presença e eficiência em cada canal de venda com nossa tecnologia de análise de mercado e precificação automática.</p>
                        {/* <br/>
                        <p>Monitore seu mercado/concorrentes e ganhe assertividade na precificação/ou/relevância nas ofertas do e-commerce e marketplaces.</p> */}
                    </div>
                    <LinkRouter to='/demonstracao'>
                        <button className='btn btn--demo-about'>
                            Demonstração gratuita
                        </button>
                    </LinkRouter>
                </div>
                <div>
                    <MainSvg className='main-image' />
                </div>
            </div>
        </section>
    )
}