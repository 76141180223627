import React from 'react';
import './App.scss';
import './sass/main.scss'
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './hooks/scrollToTop';
import Home from './pages/Home';
import MonitoramentoPrecos from './pages/MonitoramentoPrecos';
import PrecificacaoDinamica from './pages/PrecificacaoDinamica';
import MonitoramentoNetshoes from './pages/MonitoramentoNetshoes';
import Planos from './pages/Planos';
import Demonstracao from './pages/Demonstracao';
import Login from './pages/Login';
import Footer from './components/Footer';
import MonitoramentoRevendedores from './pages/MonitoramentoRevendedores';
import SolucoesPersonalizadas from './pages/SolucoesPersonalizadas';
import PageNotFound from './pages/PageNotFound';
import Privacidade from './pages/Privacidade';
import Sobre from './pages/Sobre';
import DynamicPrecification from './components/Planos/GuidedPlans/DynamicPrecification';

function App() {
  return (
    <Router basename='/'>
      <Navbar />
      <ScrollToTop />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/solucoes/monitoramento-de-concorrentes' exact component={MonitoramentoPrecos} />
        <Route path='/solucoes/precificacao-dinamica' exact component={PrecificacaoDinamica} />
        <Route path='/solucoes/monitoramento-netshoes' exact component={MonitoramentoNetshoes} />
        <Route path='/solucoes/monitoramento-de-revendedores' exact component={MonitoramentoRevendedores} />
        <Route path='/planos' exact component={Planos} />
        <Route path='/planos/precificacao' exact component={DynamicPrecification} />
        <Route path='/login' exact component={Login} />
        <Route path='/demonstracao' exact component={Demonstracao} />
        <Route path='/solucoes/solucoes-personalizadas' exact component={SolucoesPersonalizadas} />
        <Route path='/privacidade' exact component={Privacidade} />
        <Route path='/sobre' exact component={Sobre} />

        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
