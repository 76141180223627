import React from 'react';
import { ReactComponent as ResellerProblems1 } from '../../../assets/images/monitoramentorevendedores/reseller-problems-1.svg';
import { ReactComponent as ResellerProblems2 } from '../../../assets/images/monitoramentorevendedores/reseller-problems-2.svg';
import { ReactComponent as ResellerProblems3 } from '../../../assets/images/monitoramentorevendedores/reseller-problems-3.svg';
import { ReactComponent as ResellerProblems4 } from '../../../assets/images/monitoramentorevendedores/reseller-problems-4.svg';
import './ResellerProblems.scss';
import ProblemsButton from '../../ProblemsButton';

const ResellerProblems = () => {
    return (
        <section className='reseller-problems-container'>
            <div className='reseller-problems-wrapper'>
                <div className='reseller-problems-heading'>
                    <h3>Você lida com alguma dessas situações?</h3>
                </div>
                <div className='reseller-problems-area'>
                    <div className='reseller-problems-items'>
                        <div className='reseller-problems-img' data-aos="fade-down">
                            <ResellerProblems1 />
                        </div>
                        <div className='reseller-problems-text' data-aos="fade-up">
                            <p>
                                Meus revendedores precificam abaixo do preço sugerido
                            </p>
                        </div>
                    </div>
                    <div className='reseller-problems-items'>
                        <div className='reseller-problems-img coins' data-aos="fade-down">
                            <ResellerProblems2 />
                        </div>
                        <div className='reseller-problems-text' data-aos="fade-up">
                            <p>
                                Não sei como meus revendedores estão precificando
                            </p>
                        </div>
                    </div>
                    <div className='reseller-problems-items'>
                        <div className='reseller-problems-img' data-aos="fade-down">
                            <ResellerProblems3 />
                        </div>
                        <div className='reseller-problems-text' data-aos="fade-up">
                            <p>
                            Venho sofrendo perdas no mercado devido à estratégia de preços agressiva dos revendedores
                            </p>
                        </div>
                    </div>
                    <div className='reseller-problems-items'>
                        <div className='reseller-problems-img' data-aos="fade-down">
                            <ResellerProblems4 />
                        </div>
                        <div className='reseller-problems-text' data-aos="fade-up">
                            <p>
                                Gasto muito tempo monitorando manualmente meus revendedores e coletando preços
                            </p>
                        </div>
                    </div>
                </div>
                <ProblemsButton scrollTo='reseller-roadmap' />
            </div>
        </section>
    )
}

export default ResellerProblems;
