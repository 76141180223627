import React from 'react';
import { ReactComponent as NumberOne } from '../../../assets/images/monitoramentorevendedores/number-1.svg';
import { ReactComponent as NumberTwo } from '../../../assets/images/monitoramentorevendedores/number-2.svg';
import { ReactComponent as NumberThree } from '../../../assets/images/monitoramentorevendedores/number-3.svg';
import { ReactComponent as NumberFour } from '../../../assets/images/monitoramentorevendedores/number-4.svg';
import { ReactComponent as MinMaxPriceImg } from '../../../assets/images/monitoramentorevendedores/min-max-price.svg';
import { ReactComponent as RoadmapCharOne } from '../../../assets/images/monitoramentorevendedores/roadmap-char-1.svg';
import UrlRangeImg from '../../../assets/images/monitoramentorevendedores/monitored-offer.png';
import { ReactComponent as RoadmapCharTwo } from '../../../assets/images/monitoramentorevendedores/roadmap-char-2.svg';
import PerformanceEvolution from '../../../assets/images/monitoramentorevendedores/performance-evolution.png';
import { ReactComponent as RoadmapCharThree } from '../../../assets/images/monitoramentorevendedores/roadmap-char-3.svg';
import CompanyNotification from '../../../assets/images/monitoramentorevendedores/company-notification.png';
import { ReactComponent as RoadmapCharFour } from '../../../assets/images/monitoramentorevendedores/roadmap-char-4.svg';
import './ResellerRoadmap.scss';

const ResellerRoadmap = () => {
    return (
        <section
            className='reseller-roadmap-container'
            id='reseller-roadmap'
        >
            <div className='reseller-roadmap-wrapper'>
                <div className='reseller-roadmap-heading'>
                    <h3>Descubra como é fácil monitorar seus revendedores com a Hooklab</h3>
                </div>
                <div className='reseller-roadmap-content'>
                    <div className='reseller-roadmap-1 u-margin-bottom-medium'>
                        <div className='reseller-roadmap-1-infos'>
                            <div
                                className='reseller-roadmap-number'
                                data-aos='fade-right'
                            >
                                <NumberOne />
                            </div>
                            <div
                                className='reseller-roadmap-text'
                                data-aos='fade-right'
                            >
                                <p>
                                    Adicione os produtos que você deseja monitorar e o preço de revenda acordado com seu revendedor.
                                </p>
                            </div>
                        </div>
                        <div className='reseller-roadmap-1-images'>
                            <div
                                className='reseller-roadmap-1-print'
                                data-aos='fade-left'
                            >
                                <MinMaxPriceImg />
                            </div>
                            <div
                                className='reseller-roadmap-1-char'
                                data-aos='fade-left'
                                data-aos-delay='250'
                            >
                                <RoadmapCharOne />
                            </div>
                        </div>
                    </div>
                    <div className='reseller-roadmap-2 u-margin-bottom-medium'>
                        <div className='reseller-roadmap-2-images'>
                            <div
                                className='reseller-roadmap-2-print'
                                data-aos='fade-right'
                            >
                                <img src={UrlRangeImg} alt="Oferta monitorada" />
                            </div>
                            <div
                                className='reseller-roadmap-2-char'
                                data-aos='fade-right'
                                data-aos-delay='250'
                            >
                                <RoadmapCharTwo />
                            </div>
                        </div>
                        <div className='reseller-roadmap-2-infos'>
                            <div
                                className='reseller-roadmap-number'
                                data-aos='fade-left'
                            >
                                <NumberTwo />
                            </div>
                            <div
                                className='reseller-roadmap-text'
                                data-aos='fade-left'
                            >
                                <p>
                                    A Hooklab irá buscar os anúncios dos seus revendedores e monitorá-los. Você será informado se os preços praticados são coerentes com a sua política comercial.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='reseller-roadmap-3 u-margin-bottom-medium'>
                        <div className='reseller-roadmap-3-infos'>
                            <div
                                className='reseller-roadmap-number'
                                data-aos='fade-right'
                            >
                                <NumberThree />
                            </div>
                            <div
                                className='reseller-roadmap-text'
                                data-aos='fade-right'
                            >
                                <p>
                                    Você poderá agrupar sellers de diferentes canais em uma única empresa, podendo acompanhar como essa empresa vem precificando os seus produtos ao longo do tempo, e se ela está de acordo com a sua política comercial.
                                </p>
                            </div>
                        </div>
                        <div className='reseller-roadmap-3-images'>
                            <div
                                className='reseller-roadmap-3-print'
                                data-aos='fade-left'
                            >
                                <img
                                    src={PerformanceEvolution}
                                    alt={"Evolução de performance"}
                                />
                            </div>
                            <div
                                className='reseller-roadmap-3-char'
                                data-aos='fade-left'
                                data-aos-delay='250'
                            >
                                <RoadmapCharThree />
                            </div>
                        </div>
                    </div>
                    <div className='reseller-roadmap-4'>
                        <div className='reseller-roadmap-4-images'>
                            <div
                                className='reseller-roadmap-4-print'
                                data-aos='fade-right'
                            >
                                <img src={CompanyNotification} />
                            </div>
                            <div
                                className='reseller-roadmap-4-char'
                                data-aos='fade-right'
                                data-aos-delay='250'
                            >
                                <RoadmapCharFour />
                            </div>
                        </div>
                        <div className='reseller-roadmap-4-infos'>
                            <div
                                className='reseller-roadmap-number'
                                data-aos='fade-left'
                            >
                                <NumberFour />
                            </div>
                            <div
                                className='reseller-roadmap-text'
                                data-aos='fade-left'
                            >
                                <p>
                                    Você poderá programar notificações que serão enviadas automaticamente por e-mail, alertando as empresas que estiverem descumprindo a sua política comercial.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResellerRoadmap;
