import React, { useState } from 'react';
import { Questions } from './FAQData';
import './PricingFAQ.scss';

const PricingFAQ = () => {

    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if(clicked === index) {
            return setClicked(null)
        }
        setClicked(index)
    }

    return (
        <section className='pricing-faq-container'>
            <div className='pricing-faq-wrapper'>
                <div className='pricing-faq-heading'>
                    <h3>Perguntas Frequentes</h3>
                </div>
                <div className='faq-area'>
                    {Questions.map((item, index) => {
                        return (
                            <>
                                <div className='question-wrap' onClick={() => toggle(index)} key={index}>
                                    <h4>{item.question}</h4>
                                    <i className={clicked === index ? 'fas fa-minus' : 'fas fa-plus'} />
                                </div>
                                {clicked === index ? (
                                    <div className='dropdown-answer'>
                                        {item.answer.map((answer) => {
                                            return (
                                                <p>
                                                    {answer}
                                                </p>
                                            )
                                        })}
                                    </div>
                                ) : null }   
                            </>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default PricingFAQ;
