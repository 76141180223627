import React from 'react';
import DemoForm from '../components/Demonstracao/DemoForm';
import DemoInfoSection from '../components/Demonstracao/DemoInfoSection';

const Demonstracao = () => {
    return (
        <section className='demo-container'>
            <DemoInfoSection />
            <DemoForm />
        </section>
    )
};

export default Demonstracao;
