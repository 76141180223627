import React from 'react';
import './NetshoesMainSection.scss';
import {ReactComponent as NetshoesMain} from '../../../assets/images/monitoramentonetshoes/netshoes-main.svg';
import Typewriter from 'typewriter-effect';

const NetshoesMainSection = () => {
    return (
        <section className='netshoes-main-container'>
            <div className='netshoes-main-wrapper'>
                <div className='netshoes-main-text'>
                    <div className='netshoes-main-heading'>
                        <h1>Monitoramento Netshoes Hooklab</h1>
                    </div>
                    <div className='dynamic-texts-wrapper'>
                        <Typewriter
                            options={{
                            strings: ['Melhore sua performance', 'Cresça no marketplace'],
                            autoStart: true,
                            loop: true,
                            }}
                        />
                    </div>
                </div>
                <div>
                    <NetshoesMain  className='netshoes-main-image' />
                </div>
            </div>
        </section>
    )
}

export default NetshoesMainSection;
