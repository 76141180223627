import React from 'react';
import TrulyCustomImg from '../../../assets/images/precificacaodinamica/truly-custom.svg';
import './TrulyCustom.scss';

const TrulyCustom = () => {
    return (
        <section className='truly-custom-container'>
            <div className='truly-custom-wrapper'>
                <div className='truly-custom-image' data-aos="fade-right">
                    <img src={TrulyCustomImg} />
                </div>
                <div className='truly-custom-content'>
                    <div className='truly-custom-heading'>
                        <h3>Precificação personalizada</h3>
                    </div>
                    <div className='truly-custom-text'>
                        <p>
                        Nós entendemos que cada loja e linha de produtos possui suas próprias necessidades de precificação. Por isso, oferecemos regras de preços personalizadas para cada canal de venda. Permitindo criar grupos de produtos com diferentes estratégias de posicionamento no mercado, margens e muito mais.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TrulyCustom;
