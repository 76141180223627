import React from 'react';
import {ReactComponent as MonitorImg} from '../../../assets/images/monitoramentoprecos/monitor-only.svg';
import './MonitorOnly.scss';

const MonitorOnly = () => {
    return (
        <section className='monitor-only-container'>
            <div className='monitor-only-wrapper'>
                <div className='monitor-only-image' data-aos="fade-left">
                    <MonitorImg />
                </div>
                <div className='monitor-only-content'>
                    <div className='monitor-only-heading'>
                        <h3>Monitore apenas o que você precisa</h3>
                    </div>
                    <div className='monitor-only-text'>
                        <p>
                            O monitoramento de preços da Hooklab é simples e prático. Você não vai se perder em um mar de dados. 
                            Nós coletamos os dados que você precisa e fornecemos a informação tratada para suas análises. Não 
                            cobramos por anúncios inativos ou sem estoque, e, quando esses anúncios forem reativados, você ficará sabendo. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MonitorOnly;
