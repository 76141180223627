import React from 'react';
import GuidedPlans from '../components/Planos/GuidedPlans';
import PlansForm from '../components/Planos/PlansForm';

const Planos = () => {
    return (
        <div>
            <GuidedPlans />
            {/* <PlansForm /> */}
        </div>
    )
};

export default Planos;
