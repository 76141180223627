import React from 'react';
import {ReactComponent as ResellerMainImg} from '../../../assets/images/monitoramentorevendedores/reseller-main.svg';
import './ResellerMain.scss';

const ResellerMain = () => {
    return (
        <section className='reseller-main-container'>
            <div className='reseller-main-wrapper'>
                <div className='reseller-main-text'>
                    <div className='reseller-main-heading'>
                        <h1>Monitoramento de Revendedores Hooklab</h1>
                    </div>
                    <div className='reseller-main-subheading'>
                            <p>
                                Acompanhe como seus revendedores e distribuidores vêm precificando os produtos da sua marca.
                            </p>
                        </div> 
                </div>
                <div>
                    <ResellerMainImg className='reseller-main-image' />
                </div>
            </div>
        </section>
    )
}

export default ResellerMain;
