import React from 'react';
import './mainsection.scss';
import { ReactComponent as MainSvg } from '../../../assets/images/homeimgs/main-sec-svg.svg';
import Typewriter from 'typewriter-effect';
import { Link as LinkRouter } from 'react-router-dom';

const MainSection = () => {
    return (
        <>
            <section className='main-container'>
                <div className='main-wrapper'>
                    <div className='main-content'>
                        <div className='main-heading'>
                            <h1>Monitoramento de Preços & Precificação Dinâmica</h1>
                        </div>
                        <div className='dynamic-texts'>
                                <Typewriter
                                options={{
                                strings: ['Monitoramento de Concorrentes', 'Monitoramento de Revendedores', 
                                'Monitoramento Netshoes', 'Precificação Dinâmica'],
                                autoStart: true,
                                loop: true,
                                }}
                        />
                            </div>
                        <div className='main-subheading'>
                            <p>Eleve sua presença e eficiência em cada canal de venda com nossa tecnologia de análise de mercado e precificação automática.</p>
                            {/* <br/>
                            <p>Monitore seu mercado/concorrentes e ganhe assertividade na precificação/ou/relevância nas ofertas do e-commerce e marketplaces.</p> */}
                        </div>
                        <LinkRouter to='/demonstracao'>
                            <button className='btn btn--main'>
                                Demonstração gratuita
                            </button>
                        </LinkRouter>
                    </div>
                    <div>
                        <MainSvg className='main-image'/>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default MainSection;
