import { Carousel } from 'react-responsive-carousel';
import CarouselA from "../../../assets/images/precificacaodinamica/carousel-precificacao-1.svg"
import CarouselB from "../../../assets/images/precificacaodinamica/carousel-precificacao-2.svg"
import CarouselC from "../../../assets/images/precificacaodinamica/carousel-precificacao-3.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.scss";

export default function CarouselPricingRule() {
    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showStatus={false}
                showThumbs={false}
                transitionTime={200}
                interval={4000}
                showArrows={false}

            >
                <div className='car'>
                    <img alt='Interface de edição de uma regra de precificação.' src={CarouselA} />
                </div>
                <div className='car'>
                    <img alt='Interface do histórico de alterações de preço.' src={CarouselB} />
                </div>
                <div className='car'>
                    <img alt='Interface de exibição de uma regra de precificação' src={CarouselC} />
                </div>
            </Carousel>
        </div>
    )
}