import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { ReactComponent as PriceMonitImg } from '../../../../assets/images/homeimgs/price-monitoring-overview.svg';
import { MdInsights, MdStackedLineChart } from 'react-icons/md';
import { FiBox } from 'react-icons/fi'
import { BiGlasses } from 'react-icons/bi'
import './PriceMonitoringOverview.scss';

const PriceMonitoringOverview = () => {
    return (
        <section className='price-monit-container'>
            <div className='price-monit-wrapper'>
                <div data-aos='fade-right'>
                    <PriceMonitImg className='price-monit-image' />
                </div>
                <div className='price-monit-content'>
                    <div className='price-monit-heading'>
                        <h3>Monitoramento de Concorrentes</h3>
                    </div>
                    <div className='price-monit-text'>
                        <p>
                            Saiba como seus concorrentes estão precificando e melhore
                            a sua performance na hora de posicionar os seus preços
                            nas lojas virtuais.
                        </p>
                    </div>
                    <div className='price-monit-cards'>
                        <LinkRouter to='/solucoes/monitoramento-de-concorrentes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='price-monit-card'>
                                    <div className='price-monit-icon'>
                                        <MdStackedLineChart size={40} />
                                    </div>
                                    <div className='price-monit-span'>
                                        <span>Histórico de preços</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-de-concorrentes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='price-monit-card'>
                                    <div className='price-monit-icon'>
                                        <FiBox size={40} />
                                    </div>
                                    <div className='price-monit-span'>
                                        <span>Monitoramento de buy-box</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-de-concorrentes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='price-monit-card'>
                                    <div className='price-monit-icon'>
                                        <MdInsights size={40} />
                                    </div>
                                    <div className='price-monit-span'>
                                        <span>Insights</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/monitoramento-de-concorrentes'>
                            <div className='card-animation' data-aos='fade-left'>
                                <div className='price-monit-card'>
                                    <div className='price-monit-icon'>
                                        <BiGlasses size={40} />
                                    </div>
                                    <div className='price-monit-span'>
                                        <span>Busca de concorrentes</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PriceMonitoringOverview;
