import React from 'react';
import CustomSolutionsMain from '../components/SolucoesPersonalizadas/CustomSolutionsMain';
import InnovativeSolutions from '../components/SolucoesPersonalizadas/InnovativeSolutions';
import ContactForm from '../components/ContactForm';

const SolucoesPersonalizadas = () => {
    return (
        <>
            <CustomSolutionsMain />
            <InnovativeSolutions />
            <ContactForm formIdentifier='solucoes-personalizadas' bgColor='#fff' inputColor='#F0F5F8' formId='custom-solutions-form' formTitle='Precisa de uma solução que não está no mercado?'
                formSubtitle='Descreva-nos o seu problema, e um de nossos especialistas entrará em contato.' />
        </>
    )
}

export default SolucoesPersonalizadas;
