import axios from "axios";
import { useState, useEffect } from "react";

const useForm = (callback, validateInfo) => {

    const [values, setValues] = useState({
        username: '',
        email: '',
        phone: '',
        company: '',
        position: '',
        solution: 'monitoramento-de-concorrentes',
        datetime: '',
        origin: '',
        outros_origin: '',
        privacy: false
    })

    const [errors, setErrors] = useState({})

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleChange = e => {
        const { name, value } = e.target

        console.log(name, value)

        if (e.target.type === 'checkbox') {
            setValues({
                ...values,
                [name]: e.target.checked
            })
        } else {
            setValues({
                ...values,
                [name]: value
            })
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        setErrors(validateInfo(values));
        setIsSubmitting(true)
    }

    const saveToDatabase = async () => {
        await axios.get(`https://www.hooklab.com.br/pt-br/http/testcontact.php?email=${values.email}&username=${values.username}&phone=${values.phone}&message=${values.message}&company=${values.company}`);
    }


    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            (async () => {
                try {

                    const body = {
                        ...values,
                        token_rdstation: "7f09339e93ab595d0fcbb0495c57608a",
                        form_url: "https://hooklab.com.br/",
                        page_title: "Hooklab",
                        identificador: `solicitação de demonstração - ${values.solution}`
                    }

                    await axios.post(`https://www.rdstation.com.br/api/1.3/form-integrations`, body, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    await saveToDatabase();
                    callback();


                } catch (error) {
                    console.log(error.message);
                    await saveToDatabase();
                    alert('Ocorreu um erro ao tentar encaminhar essa mensagem... Entre em contato conosco pelo Whatsapp.')
                }
            })()

        }
    }, [errors])

    return { handleChange, values, handleSubmit, errors }
}

export default useForm;
