import React from 'react';
import ContactForm from '../components/ContactForm';
import Categories from '../components/MonitoramentoPrecos/Categories';
import MainSection from '../components/MonitoramentoPrecos/MainSection';
import MarketMonitoring from '../components/MonitoramentoPrecos/MarketMonitoring';
import MonitoringFAQ from '../components/MonitoramentoPrecos/MonitoringFAQ';
import MonitorOnly from '../components/MonitoramentoPrecos/MonitorOnly';
import PlansSection from '../components/MonitoramentoPrecos/PlansSection';
import Problems from '../components/MonitoramentoPrecos/Problems';
import ProductMatching from '../components/MonitoramentoPrecos/ProductMatching';
import Video from '../components/MonitoramentoPrecos/Video';
import Alerts from '../components/MonitoramentoPrecos/Alerts';

const MonitoramentoPrecos = () => {
    return (
        <>
            <MainSection />
            <Problems />
            <Video />
            <MarketMonitoring />
            <MonitorOnly />
            <ProductMatching />
            <Alerts />
            <Categories />
            <PlansSection />
            <MonitoringFAQ />
            <ContactForm formIdentifier='monitoramento-concorrentes' bgColor='#fff' inputColor='#F0F5F8' />
        </>
    )
};

export default MonitoramentoPrecos;
