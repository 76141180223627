import React from 'react';
import ContactForm from '../components/ContactForm';
import CustomerView from '../components/PrecificacaoDinamica/CustomerView';
import PricingFAQ from '../components/PrecificacaoDinamica/PricingFAQ';
import PricingMain from '../components/PrecificacaoDinamica/PricingMainSection';
import PricingPlans from '../components/PrecificacaoDinamica/PricingPlans';
import PricingProblems from '../components/PrecificacaoDinamica/PricingProblems';
import PricingRules from '../components/PrecificacaoDinamica/PricingRules';
import SafetyFirst from '../components/PrecificacaoDinamica/SafetyFirst';
import Transparency from '../components/PrecificacaoDinamica/Transparency';
import TrulyCustom from '../components/PrecificacaoDinamica/TrulyCustom';

const PrecificacaoDinamica = () => {
    return (
        <div>
            <PricingMain />
            <PricingProblems />
            <PricingRules />
            <CustomerView />
            <SafetyFirst />
            <TrulyCustom />
            <Transparency />
            <PricingPlans />
            <PricingFAQ />
            <ContactForm formIdentifier='precificacao-dinamica' />
        </div>
    )
};

export default PrecificacaoDinamica;
