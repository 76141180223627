import { ReactComponent as ResellerMonitoringImg } from '../../../assets/images/homeimgs/reseller-monitoring.svg'
import "./About.scss"

export default function About() {
    return (
        <section className='about-container'>
                <div className='about-wrapper'>
                    <div className='about-content'>
                        <div className='about-heading'>
                            <h3>Muito Prazer, somos a Hooklab!</h3>
                        </div>
                        <div className='about-text'>
                            <p>
                                Somos uma startup de Florianópolis. Começamos a trabalhar com monitoramento de preços para a Netshoes, e a partir de então nos apaixonamos por desenvolver tecnologias que otimizem os resultados de empresas nos canais digitais. Procuramos sempre por novas tecnologias e meios de inovar no online, para entregar as melhores soluções para quem sabe como é o dia-a-dia em ecommerces e marketplaces.
                            </p>
                        </div>

                    </div>
                    <div data-aos='fade-left'>
                        <ResellerMonitoringImg className='about-image' />
                    </div>
                </div>
            </section>
    )
}