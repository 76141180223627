import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import './pricing-button.styles.scss';

const PricingButton = () => {
    return (
        <div className='pricing-button'>
            <LinkRouter to='/planos'>
                <button>Confira nossos preços</button>
            </LinkRouter>
        </div>
    )
};

export default PricingButton;