import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import './CustomSolutionsOverview.scss';
import { ReactComponent as CustomSolutionsImg } from '../../../../assets/images/homeimgs/custom-solutions-overview.svg';
import IdeaCustomImg from '../../../../assets/images/homeimgs/idea.png';
import RulerCustomImg from '../../../../assets/images/homeimgs/ruler.png';

const CustomSolutionsOverview = () => {
    return (
        <section className='custom-overview-container'>
            <div className='custom-overview-wrapper'>
                <div data-aos='fade-right'>
                    <CustomSolutionsImg className='custom-overview-image' />
                </div>
                <div className='custom-overview-content'>
                    <div className='custom-overview-heading'>
                        <h3>Soluções Personalizadas</h3>
                    </div>
                    <div className='custom-overview-text'>
                        <p>
                            A Hooklab sabe que existem problemas que requerem soluções que não estão no mercado. Se a sua demanda não se 
                            encaixa em algum de nossos produtos, fale conosco!
                        </p>
                    </div>
            
                </div>
            </div>
        </section>
    )
}

export default CustomSolutionsOverview;
