import React from 'react';
import { ReactComponent as MainPricingImg } from '../../../assets/images/precificacaodinamica/main-image.svg';
import './PricingMainSection.scss';
import Typewriter from 'typewriter-effect';

const PricingMain = () => {
    return (
        <section className='pricing-main-container'>
            <div className='pricing-main-wrapper'>
                <div className='pricing-main-text'>
                    <div className='pricing-main-heading'>
                        <h1>Precificação Dinâmica Hooklab</h1>
                    </div>
                    <div className='dynamic-words'>
                        <Typewriter
                            options={{
                                strings: ['Eficiência', 'Competitividade', 'Inteligência'],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </div>
                    <div className='pricing-main-subheading'>
                        <p>
                            Coloque sua empresa à frente da concorrência com uma estratégia de precificação dinâmica eficiente. Posicione seus preços para maximizar lucros e ganhar vantagem competitiva.
                        </p>
                    </div>
                </div>
                <div>
                    <MainPricingImg className='pricing-main-image' />
                </div>
            </div>
        </section>
    )
}

export default PricingMain;
