import "./Monitoring.scss"
import InputLenght from "../InputLenght/inputLength"
import { useLayoutEffect, useState } from "react"
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai"
import { Link } from "react-router-dom"
import ContactForm from "../../../ContactForm"

export default function Monitoring({ view, handleClose }) {

    const [active, setActive] = useState(false)

    useLayoutEffect(() => {
        view === "monitoring" ? setActive(true) : setActive(false)
        console.log(view.view);
        window.scrollBy(0, 0)
    }, [view])




    return (
        active ?
            <section >
                <div id="solution" className="container container--solution">
                    <div className="solution-main-wrapper">
                        <div className="heading-secondary heading-secondary--main">
                            <h3>Monitoramento de Concorrentes</h3>

                        </div>
                        <div className="heading-secondary--sub">
                            <p>Monitore o preço dos seus concorrentes e melhore o seu posicionamento no mercado</p>
                        </div>
                        <div className="solution solution--1">
                            <div className="solution__heading">
                                <h4 className="solution__heading--title">Benefícios da Solução</h4>
                            </div>
                            <div className="solution__boding">
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Monitoramento de preços de concorrentes</h4>
                                        <p className="heading-tertiary--sub">Você poderá monitorar os anúncios dos seus concorrentes e saber como seus produtos estão posicionados do mercado</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Matching de produtos</h4>
                                        <p className="heading-tertiary--sub">A Hooklab irá encontrar os produtos de concorrentes que estão competindo diretamente com você</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Histórico de preços</h4>
                                        <p className="heading-tertiary--sub">Você terá acesso ao histórico de todas ofertas que estiverem sendo monitoradas</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Segmentação por tags e filtros</h4>
                                        <p className="heading-tertiary--sub">Você poderá segmetar os seus produtos e o produtos dos seus concorrentes utilizando tags e filtros.</p>
                                    </div>
                                </div>
                                <div
                                    className="solution__boding--row solution__boding--row--center">
                                    <Link
                                        to='/solucoes/monitoramento-de-concorrentes'
                                        className='btn--link solution__boding--row--center__button'
                                    >
                                        <AiOutlinePlus />
                                        Saiba mais sobre a solução
                                    </Link>
                                </div>
                                <InputLenght
                                    skuPrice={3}
                                    channelPrice={2.40}
                                    title='Quantos produtos você deseja monitorar?'
                                    view={view}
                                />
                                <div
                                    className="btn--link-wrapper"
                                >
                                    <button
                                        className='btn--link'
                                        onClick={handleClose}
                                    >
                                        Escolher outra solução
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContactForm formTitle='Fale com um dos nossos consultores' formIdentifier='planos-monitoramento-concorrentes' bgColor='#fff' inputColor='#F0F5F8' />

                </div>
            </section>
            : null
    )
}