import './Values.scss';
import FlipCard from './FlipCard';
import { values } from './values';

export default function Values() {

    return (
        <section className='container container--values'>
            <div className='wrapper wrapper--values'>
                <div className='values-content'>
                    <div className='heading-secondary heading-secondary--main u-margin-top'>
                        <h3>Nossos valores</h3>
                    </div>
                    <div className='heading-secondary--sub'>
                        Acreditamos que a tecnologia pode transformar o varejo e estamos empenhados em construir esse futuro juntos
                    </div>
                    <div className='values-cards' data-aos='fade-right'>
                        {values.map((value, index) => {
                            return (
                                <FlipCard
                                    key={index}
                                    title={value.title}
                                    description={value.description}
                                    icon={value.icon}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};