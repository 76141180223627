import React from 'react';
import { ReactComponent as Chart } from '../../../assets/images/monitoramentoprecos/monitoramento-de-mercado.svg';

import './MarketMonitoring.scss';

const MarketMonitoring = () => {
    return (
        <>
            <div className='market-monitoring-container' id='market-monitoring'>
                <div className='market-monitoring-wrapper'>
                    <div className='market-monitoring-heading'>
                        <h3>Monitoramento de Mercado</h3>
                    </div>
                    <div className='market-monitoring-img' data-aos="fade-up">
                        <Chart height={"600px"} />
                    </div>
                    <div className='market-monitoring-text'>
                        <p>
                            Entenda o posicionamento dos seus produtos ao comparar preço com o mercado. Sabemos dos desafios de otimizar cada canal de venda e de precificar com maior assertividade. Por isso, a Hooklab oferece um dashboard dos seus produtos, que compara suas ofertas com as disponíveis dos seus concorrentes em cada canal de venda. Com essa ferramenta, você pode identificar quais ações podem ser realizadas para posicionar seus produtos de forma mais competitiva no mercado.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketMonitoring;
