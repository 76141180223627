import React from 'react';
import ResellerMain from '../components/MonitoramentoRevendedores/ResellerMain';
import ResellerPlans from '../components/MonitoramentoRevendedores/ResellerPlans';
import ResellerProblems from '../components/MonitoramentoRevendedores/ResellerProblems';
import ResellerRoadmap from '../components/MonitoramentoRevendedores/ResellerRoadmap';
import ContactForm from '../components/ContactForm';

const MonitoramentoRevendedores = () => {
    return (
        <>
            <ResellerMain />
            <ResellerProblems />
            <ResellerRoadmap />
            <ResellerPlans />
            <ContactForm formIdentifier='monitoramento-revendedores' bgColor='#fff' inputColor='#F0F5F8'/>
        </>
    )
}

export default MonitoramentoRevendedores;
