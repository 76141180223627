import React from 'react';

const Login = () => {

    return (
        <>
           <h1>Login</h1> 
        </>
    );
};

export default Login;