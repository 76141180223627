import React from 'react';
import {ReactComponent as CustomSolutionsMainImg} from '../../../assets/images/solucoespersonalizadas/custom-solutions-main.svg';
import './CustomSolutionsMain.scss';

const CustomSolutionsMain = () => {
    return (
        <section className='custom-solutions-container'>
            <div className='custom-solutions-wrapper'>
                <div className='custom-solutions-text'>
                    <div className='custom-solutions-heading'>
                        <h1>Soluções Personalizadas Hooklab</h1>                    
                    </div>
                    <div className='custom-solutions-subheading'>
                        <p>
                            A Hooklab carrega inovação e ousadia nas suas veias, de qual solução você precisa?
                        </p>
                    </div>
                </div>
                <div>
                    <CustomSolutionsMainImg className='custom-solutions-image'/>
                </div>
            </div>
        </section>
    )
}

export default CustomSolutionsMain;
