import React from 'react';
import './cases.scss';

const Cases = () => {


    return (
        <section className='cases-container'>
            <div className='cases-wrapper'>
                <div className='cases-heading'>
                    <h3>Nós acreditamos na transformação digital do varejo</h3>
                </div>
                <div className='cases-text'>
                    <p>
                        Com as diferentes opções de compra no ambiente digital afetando a decisão do consumidor, é importante que os lojistas utilizem tecnologias apropriadas para aumentar sua eficiência e alcance em diversos canais de venda. A Hooklab pode ajudá-lo a analisar o mercado e definir preços competitivos de forma ágil.     </p>
                </div>

            </div>
        </section>
    )
}

export default Cases;
