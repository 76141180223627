export const posts = [
    {
        id: 1,
        title: 'Como os preços impactam no sucesso do seu comércio eletrônico',
        date: '2020-04-08',
        image: 'https://hooklab.com.br/blog/static/fe79b4f9afdb5175d9b699fe238b7f22/b0f81/01-main.webp',
        description: 'Entenda qual a importância da precificação inteligente para seu crescimento em um marketplace e como você pode garantir o sucesso das suas vendas.',
        link: 'https://hooklab.com.br/blog/como-os-precos-impactam-no-sucesso-do-seu-comercio-eletronico/'
    },
    {
        id: 2,
        title: 'Coronavírus e vendas: como se adaptar a esta crise?',
        date: '2020-04-14',
        image: 'https://hooklab.com.br/blog/static/ba6aea05f7b4701863a6dd5bfdcf35da/b0f81/02-main.webp',
        description: 'Não importa o tamanho do seu negócio; você, assim como a maioria dos lojistas está sendo afetado pela pandemia do coronavírus. Neste momento, é importante que você consiga se adaptar para gerenciar as suas vendas da melhor forma possível.',
        link: 'https://hooklab.com.br/blog/coronavirus-e-vendas-como-se-adaptar-a-esta-crise/'
    },
    {
        id: 3,
        title: 'Quais os resultados em monitorar meus concorrentes no marketplace?',
        date: '2020-04-22',
        image: 'https://hooklab.com.br/blog/static/5db4edbf2153b3da9c7aa0fe793e4c1d/b0f81/03-main.webp',
        description: 'Conheça as vantagens do monitoramento de concorrentes em um mercado altamente dinâmico como o varejo online/digital/eletrônico, a saiba como é possível se antecipar ao mercado.',
        link: 'https://hooklab.com.br/blog/quais-os-resultados-em-monitorar-meus-concorrentes-no-marketplace/'
    },
    {
        id: 4,
        title: 'Por que otimizar seu preço no e-commerce ou marketplace',
        date: '2020-04-29',
        image: 'https://hooklab.com.br/blog/static/a2fc59971a345e057af599e7862ccb1c/b0f81/04-main.webp',
        description: 'Saber como precificar seu produto é fundamental para ter um melhor desempenho de vendas. Mas dentro do varejo digital é essencial possuir dados do setor em que você atua para conseguir vender melhor.',
        link: 'https://hooklab.com.br/blog/por-que-otimizar-seu-preco-no-ecommerce-ou-marketplace/'
    },
    {
        id: 5,
        title: 'Como aumentar suas vendas na Netshoes e Zattini',
        date: '2020-05-06',
        image: 'https://hooklab.com.br/blog/static/57585e2b92ce2bcce744d9ef6ba1c52e/b0f81/05-main.webp',
        description: 'Saiba como aumentar as suas vendas nos marketplaces Netshoes e Zattini com a plataforma Hooklab de monitoramento de preços e concorrência.',
        link: 'https://hooklab.com.br/blog/como-aumentar-suas-vendas-na-netshoes-e-zattini/'
    },
    {
        id: 6,
        title: 'Como funciona o buy-box da Netshoes?',
        date: '2020-12-04',
        image: 'https://hooklab.com.br/blog/static/4af2d44935fcf40fcaf136528f56cf4a/b0f81/06-main.webp',
        description: 'Você já se perguntou como funciona o buy-box da Netshoes? Quais são os fatores que levam você a estar vendendo um produto que vários outros lojistas também estão vendendo? É relevante eu assumir o buy-box de um produto?',
        link: 'https://hooklab.com.br/blog/como-funciona-o-buy-box-da-netshoes/'
    },
    {
        id: 7,
        title: 'Porque a curva ABC é ultrapassada para a Netshoes',
        date: '2020-12-11',
        image: 'https://hooklab.com.br/blog/static/cc5fb0c90bc8c0ae24f3d9dac8e2e6a5/b0f81/07-main.webp',
        description: 'A análise das suas vendas é uma ferramenta essencial para a gestão de estoque dos produtos de um lojista. O que é feito tradicionalmente é a análise de curvas ABC, técnica amplamente utilizada para lojas saberem quais dos seus produtos são mais rentáveis. Porém, existem informações ainda mais valiosas na Netshoes que a curva ABC não mostra.',
        link: 'https://hooklab.com.br/blog/porque-a-curva-abc-e-ultrapassada-para-a-netshoes/'
    },
    {
        id: 8,
        title: 'Vale a pena vender na Netshoes?',
        date: '2021-02-08',
        image: 'https://hooklab.com.br/blog/static/4c79a2466c480e52147022558b45dfbf/b0f81/08-main.webp',
        description: 'A Netshoes é o maior e-commerce de lifestyle esportivo da América Latina. Será que expandir sua operação para esse marketplace é vantajoso? Reunimos neste artigo alguns pontos positivos e negativos para você tomar sua decisão!',
        link: 'https://hooklab.com.br/blog/vale-a-pena-vender-na-netshoes/'
    },
    {
        id: 9,
        title: '5 dicas para começar a monitorar preços de concorrentes no online',
        date: '2021-11-18',
        image: 'https://hooklab.com.br/blog/static/3407af738639eb34c19baa3e105990f0/b0f81/09-main.webp',
        description: 'O monitoramento de concorrentes sempre pode oferecer insights a serem incorporados nas suas estratégias comerciais. Esse monitoramento pode ser realizado olhando para várias áreas: satisfação dos clientes, posicionamento em redes sociais, política de preços e estoques, portfólio de produtos e canais trabalhados (tem e-commerce? em quais marketplaces está?)',
        link: 'https://hooklab.com.br/blog/5-dicas-para-comecar-a-monitorar-precos-de-concorrentes-no-online/'
    },
    {
        id: 10,
        title: '3 estratégias para precificar seus produtos no online',
        date: '2021-11-30',
        image: 'https://hooklab.com.br/blog/static/57f8814cc062a33e291d770f93b6521e/b0f81/10-main.webp',
        description: 'A estratégia de precificação que você adota para seus produtos precisa ser coerente com os objetivos da sua empresa, e por isso é necessário encontrar um método capaz de lhe guiar ao objetivo. Os objetivos de uma empresa podem ser tanto quantitativos - aumento da margem de lucro, aumento das vendas, aumento da fatia de mercado - assim como qualitativos - atração de clientes, prática de um preço justo, sobrevivência no longo prazo, desencorajamento de entrantes no mercado.',
        link: 'https://hooklab.com.br/blog/3-estrategias-para-precificar-seus-produtos-no-online/'
    },
    {
        id: 11,
        title: 'Precificação online: o que é, qual sua importância, online x físico',
        date: '2022-01-14',
        image: 'https://hooklab.com.br/blog/static/c85ff6c4edc89986ac16fc7411e42786/4d586/11-main.webp',
        description: 'Precificar no ambiente on-line não é muito diferente do ambiente físico: a matemática continua a mesma e a conta continua tendo que fechar, porém, o que entra nessa conta muda, assim como o posicionamento no mercado.',
        link: 'https://hooklab.com.br/blog/precificacao-online-o-que-e-qual-sua-importancia-online-x-fisico/'
    },
    {
        id: 12,
        title: 'Como melhorar a performance em marketplaces se diferenciando',
        date: '2022-01-21',
        image: 'https://hooklab.com.br/blog/static/e1d0cacb32038c78fca928810a09220c/1c73b/12-main.webp',
        description: 'Vender em marketplaces é uma estratégia muito utilizada por empresas que vendem online pela possibilidade de potencializar o tamanho de uma operação, já que esses canais recebem um número gigantesco de visitantes, e, consequentemente, um número muito grande de clientes.',
        link: 'https://hooklab.com.br/blog/como-melhorar-a-performance-em-marketplaces-se-diferenciando/'
    },
    {
        id: 13,
        title: 'Preço Mínimo Anunciado (PMA): o que é, importância, e como fazer',
        date: '2022-01-21',
        image: 'https://hooklab.com.br/blog/static/5c14ae4c136a935a946f15a1ee84305e/a99fa/13-main.webp',
        description: 'Você já leu ou ouviu falar de preço mínimo anunciado em algum lugar? Essa prática vêm se tornando cada vez mais comum nas políticas comerciais das fabricantes do país, principalmente nos canais digitais de venda.',
        link: 'https://hooklab.com.br/blog/preco-minimo-anunciado-PMA-o-que-e-importancia-e-como-fazer/'
    },
    {
        id: 14,
        title: 'Hooklab no Miditec 2022?',
        date: '2022-01-27',
        image: 'https://hooklab.com.br/blog/static/ed7bcd136a5b1b6716fb47b59c0790b8/193e9/14-main.webp',
        description: 'Em dezembro de 2021 a Hooklab participou de um bootcamp junto a mais 20 empresas, onde conquistou o top 5 e foi pré-selecionada para a Miditec.',
        link: 'https://hooklab.com.br/blog/hooklab-na-miditec-22/'
    },
    {
        id: 15,
        title: 'Como proteger minha marca?  3 dicas especiais',
        date: '2022-02-07',
        image: 'https://hooklab.com.br/blog/static/256a9c1062261fa59a1dbb55f0c1adef/4d586/15-main.webp',
        description: 'Existem várias formas de proteger uma marca: através de registro de marca, estratégias comerciais e branding no marketing. Todos eles são importantes para a construção de um nome e imagens fortes que levam a perseverança no mercado.',
        link: 'https://hooklab.com.br/blog/como-proteger-a-minha-marca-3-dicas-especiais/'
    },
    {
        id: 15,
        title: 'A importância do preço sugerido',
        date: '2024-04-10',
        image: 'https://hooklab.com.br/blog/static/d6f5648e49f20ea7ef175a6ced0ffae4/77d9d/main.webp',
        description: 'Por acaso você sabe a importância que um preço sugerido oferece para o processo de venda? Vem com a gente que hoje explicaremos tudo o que você precisa saber sobre isso.',
        link: 'https://hooklab.com.br/blog/importancia-do-preco-sugerido/'
    },
    {
        id: 15,
        title: 'Precificação e concorrência',
        date: '2024-05-31',
        image: 'https://hooklab.com.br/blog/static/7b183047af8eddae786af0ec55894817/8bf39/capa.webp',
        description: 'Neste post entenderemos melhor sobre a importância da análise concorrencial na precificação.',
        link: 'https://hooklab.com.br/blog/precificacao-e-concorrencia/'
    },
    {
        id: 15,
        title: 'CMV: O que é, por que existe e como usar',
        date: '2024-05-07',
        image: 'https://hooklab.com.br/blog/static/5d8ea6bb7f0e9037b4b94d80d3a78dc5/6a26a/capa.webp',
        description: 'Vem com a gente que neste post falaremos sobre CMV, o Custo de Mercadorias Vendidas.',
        link: 'https://hooklab.com.br/blog/cmv-o-que-e-por-que-existe-e-como-usar/'
    }
];