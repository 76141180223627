import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import './DynamicPricingOverview.scss';
import DynamPricingIcon from '../../../../assets/images/homeimgs/check.png';
import { ReactComponent as DynamPricingOverviewImg } from '../../../../assets/images/homeimgs/dynamic-pricing-overview.svg';
import { AiOutlineCheck } from 'react-icons/ai';

const DynamicPricingOverview = () => {
    return (
        <section className='dynam-pricing-container'>
            <div className='dynam-pricing-wrapper'>
                <div className='dynam-pricing-content'>
                    <div className='dynam-pricing-heading'>
                        <h3>Precificação Dinâmica</h3>
                    </div>
                    <div className='dynam-pricing-text'>
                        <p>
                            Automatize a precificação de suas ofertas a partir de regras
                            de precificação personalizadas
                        </p>
                    </div>
                    <div className='dynam-pricing-cards'>
                        <LinkRouter to='/solucoes/precificacao-dinamica'>
                            <div className='card-animation' data-aos='fade-right'>
                                <div className='dynam-pricing-card'>
                                    <div className='dynam-pricing-icon'>
                                        <AiOutlineCheck size={24} />
                                    </div>
                                    <div className='dynam-pricing-span'>
                                        <span>Integração com seu integrador, HUB ou ERPP</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/precificacao-dinamica'>
                            <div className='card-animation' data-aos='fade-right'>
                                <div className='dynam-pricing-card'>
                                    <div className='dynam-pricing-icon'>
                                        <AiOutlineCheck size={24} />
                                    </div>
                                    <div className='dynam-pricing-span'>
                                        <span>Precifique com base nos preços de mercado, custo dos produtos e estoque</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                        <LinkRouter to='/solucoes/precificacao-dinamica'>
                            <div className='card-animation' data-aos='fade-right'>
                                <div className='dynam-pricing-card'>
                                    <div className='dynam-pricing-icon'>
                                        <AiOutlineCheck size={24} />
                                    </div>
                                    <div className='dynam-pricing-span'>
                                        <span>Simulador de regras de precificação</span>
                                    </div>
                                </div>
                            </div>
                        </LinkRouter>
                    </div>
                </div>
                <div data-aos='fade-left'>
                    <DynamPricingOverviewImg className='dynam-pricing-image' />
                </div>
            </div>
        </section>
    )
}

export default DynamicPricingOverview;
