import React, { useState } from 'react';
import { Questions } from './FAQData';
import './NetshoesFAQ.scss';

const NetshoesFAQ = () => {

    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if(clicked === index) {
            return setClicked(null)
        }
        setClicked(index)
    }

    return (
        <section className='pricing-faq-container netshoes-faq'>
            <div className='pricing-faq-wrapper netshoes-wrapper'>
                <div className='pricing-faq-heading'>
                    <h3>Perguntas Frequentes</h3>
                </div>
                <div className='faq-area'>
                    {Questions.map((item, index) => {
                        return (
                            <>
                                <div className='question-wrap netshoes-question' onClick={() => toggle(index)} key={index}>
                                    <h4>{item.question}</h4>
                                    <i className={clicked === index ? 'fas fa-minus' : 'fas fa-plus'} />
                                </div>
                                {clicked === index ? (
                                    <div className='dropdown-answer'>
                                        <p>{item.answer}</p>
                                    </div>
                                ) : null }   
                            </>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default NetshoesFAQ;
