import React from 'react';
import {ReactComponent as InnovativeSolutionsImg} from '../../../assets/images/solucoespersonalizadas/innovative-solutions.svg';
import './InnovativeSolutions.scss';
import { Link as LinkScroll } from 'react-scroll';

const InnovativeSolutions = () => {
    return (
        <section className='innovative-solutions-container'>
            <div className='innovative-solutions-wrapper'>
                <div className='innovative-solutions-image' data-aos="fade-up">
                    <InnovativeSolutionsImg />
                </div>
                <div className='innovative-solutions-content'>
                    <div className='innovative-solutions-heading'>
                        <h3>Soluções personalizadas e inovadoras</h3>
                    </div>
                    <div className='innovative-solutions-text'>
                        <p>
                            Desde 2020 a Hooklab vem desenvolvendo soluções para vários players do mercado digital. 
                            Se a sua demanda não se encaixa em algum de nossos produtos, isso não quer dizer que o 
                            nosso contato precisa acabar por aqui!
                        </p>
                    </div>
                    <div className='innovative-solutions-button'>
                        <LinkScroll to='custom-solutions-form' smooth={true} spy={true} exact='true' offset={-80}>
                            <button>Fale com um consultor</button>
                        </LinkScroll>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InnovativeSolutions;
