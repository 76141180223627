import React, { useState } from 'react';
import { BiPlay } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import VideoModal from '../VideoModal';
import './DemoInfoSection.scss';
import FillEmail from './FillEmail';

const DemoInfoSection = () => {

    const [viewModal, setViewModal] = useState(false);
    const [viewFillEmail, setViewFillEmail] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState('');

    return (
        <>
            <section className='demo-info-wrapper'>
                <div className='demo-info-heading'>
                    <h3>Demonstração da Plataforma da Hooklab</h3>
                </div>
                <div className='demo-info-subheading'>

                    <p>
                        Você pode tanto assistir uma demonstração gravada, onde mostramos todas as funcionalidades da ferramenta, assim como pode agendar uma demonstração ao vivo com um de nossos consultores.
                    </p>
                    <p>
                        Para assistir a demonstração gravada basta selecionar a solução a qual te interessa e inserir seu e-mail, e o vídeo de demonstração aparecerá em seguida.
                    </p>
                    <p>
                        Para agendar a demonstração com a nossa equipe é só escolhar em qual horário você prefere. Entraremos em contato rapidamente para organizar a vídeo-conferência.
                    </p>
                </div>

                <div className='watch-demo'>
                    <div className='watch-headline'>
                        <h2>Assista à uma demonstração</h2>
                        {viewFillEmail && <button onClick={() => setViewFillEmail(false)}><AiOutlineClose /></button>}
                    </div>
                    {viewFillEmail ?
                        <FillEmail
                            setSelectedProduct={setSelectedProduct}
                            selectedProduct={selectedProduct}
                            setViewFillEmail={setViewFillEmail}
                            setViewModal={setViewModal}
                        />
                        :
                        <>
                            <button className='black-btn' style={{ marginBottom: 15 }} onClick={() => { setViewFillEmail(true); setSelectedProduct('monitoramento de concorrentes') }}>
                                <BiPlay />
                                Monitoramento de Concorrentes
                            </button>
                            <button className='blue-btn' onClick={() => { setViewFillEmail(true); setSelectedProduct('monitoramento de revendedores') }}>
                                <BiPlay />
                                Monitoramento de Revendedores
                            </button>
                        </>
                    }
                </div>
            </section>
            {viewModal && <VideoModal videoURL={selectedProduct === 'monitoramento de concorrentes' ? `https://www.youtube.com/embed/TiI3aHI0E9Y` : `https://www.youtube.com/embed/bvI9aTHFPh8`} view={viewModal} setView={setViewModal} />}
        </>
    )
}



export default DemoInfoSection;
