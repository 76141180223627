import React from 'react';
import './ProductMatching.scss';
import { ReactComponent as Matching } from '../../../assets/images/monitoramentoprecos/product-matching.svg';

const ProductMatching = () => {
    return (
        <section className='product-matching-container'>
            <div className='product-matching-wrapper'>
                <div className='product-matching-content'>
                    <div className='product-matching-title'>
                        <h3>Matching de Anúncios</h3>
                    </div>
                    <div className='product-matching-text'>
                        <p>
                            Aumente sua competitividade no online com a ajuda da Hooklab, e descubra quais concorrentes estão disputando o mercado com você. Nós buscamos ofertas concorrentes para cada produto que você comercializa, permitindo comparar seus preços e monitorar os produtos e lojistas que são mais relevantes para sua estratégia comercial. Melhore seu posicionamento de preços em relação aos concorrentes com a Hooklab.
                        </p>
                    </div>
                </div>
                <div className='product-matching-image' data-aos="fade-left">
                    <Matching />
                </div>
            </div>
        </section>
    )
}

export default ProductMatching;
