export const Questions = [
    {
        question: 'O que é Buy-box?',
        answer: 'O Buy-Box é a caixa que exibe o nome do Seller vendedor. Como são diversos vendedores que oferecem o mesmo produto, o marketplace Netshoes seleciona apenas um Seller para exibir no anúncio e assim apresentar aos clientes a principal oferta para cada grade ou variação do produto.'
    },
    {
        question: 'Por que uma solução personalizada para a Netshoes?',
        answer: 'A Netshoes é um marketplace de nicho, e por isso abriga uma gama de produtos pequena em relação ao restante do mercado. Por esse motivo, é necessário ter uma solução que atenda os players desse marketplace.'
    },
    {
        question: 'O que muda do monitoramento de concorrentes para o monitoramento da Netshoes?',
        answer: 'Principalmente a visualização e o tratamento dos dados. O monitoramento é feito da mesma forma, porém, exibimos as informações de uma forma específica para os sellers da Netshoes.'
    },
   
];