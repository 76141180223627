import React from 'react';
import {ReactComponent as PlansImg} from '../../../assets/images/monitoramentoprecos/plans.svg';
import PricingButton from '../../PricingButton';
import './PlansSection.scss';

const PlansSection = () => {
    return (
        <section className='plans-section-container'>
            <div className='plans-section-wrapper'>
                <div className='plans-section-image' data-aos="fade-up">
                    <PlansImg />
                </div>
                <div className='plans-section-content'>
                    <div className='plans-section-heading'>
                        <h3>Confira nossos preços!</h3>
                    </div>
                    <div className='plans-section-text'>
                        <p>
                            Deseja conferir nossos preços e conversar com um de nossos especialistas para ver qual plano mais 
                            se adequa à sua demanda?
                        </p>
                    </div>
                    <PricingButton />
                </div>
            </div>
        </section>
    )
}

export default PlansSection;
