/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./inputLenght.scss";
import { RangeStepInput } from 'react-range-step-input'



const sliderMinQuantity = {
    reseller: 50,
    monitoring: 100,
    dynamic: 100
}

const sliderMaxQuantity = {
    monitoring: 501,
    reseller: 301,
    dynamic: 501
}

const channelsMinQuantity = 3
const channelsMaxQuantity = 10
const minPrice = 199
const stepSize = 10


export default function InputLenght({ title, skuPrice, channelPrice, view }) {


    const [product, setProduct] = useState(sliderMinQuantity[view])
    const [channel, setChannel] = useState(channelsMinQuantity);
    const [finalResult, setFinalResult] = useState(minPrice)

    useEffect(() => {
        handleChange()
    }, [product, channel])

    function handleChange() {
        const calculo = (((product ** (1 / 2)) * skuPrice) * channelPrice * channel)
        setFinalResult(calculo)
        return
    }

    function handleChannel(a, b) {

        const value = a + b
        if (value >= channelsMinQuantity && value <= channelsMaxQuantity) {
            return setChannel(value)
        }
    }

    const openForm = () => {
        const form = document.querySelector('#form');
        form.scrollIntoView({
            behavior: 'smooth',

        })
    }

    return (
        <div className="input">
            <div className="input--heading">
                <h4>{title}</h4>
                <p className="heading-tertiary--sub">Arraste aqui quantos produtos do seu catálogo você deseja monitorar concorrentes</p>
            </div>
            <div className="input--boding">
                <RangeStepInput
                    style={{ width: '100%' }}
                    min={sliderMinQuantity[view]}
                    value={product}
                    max={sliderMaxQuantity[view]}
                    step={stepSize}
                    onChange={(e) => setProduct(e.target.value)
                    }
                />
                <div
                    style={{
                        marginTop: 10,
                        fontWeight: 500
                    }}
                >
                    {product === 1 ? '1 produto' : product >= sliderMaxQuantity[view] ? `${sliderMaxQuantity[view]}+ produtos` : `${product} produtos`}
                </div>
            </div>

            <div className="input--heading" style={{ paddingTop: "1.5rem" }}>
                <h4>Em quantos canais você deseja monitorar?</h4>
                <p className="heading-tertiary--sub">E-commerce e/ou marketplaces que deseja monitorar</p>
            </div>
            <div className="input--boding">
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "40px",
                }}
                >
                    <div className="input__handler">
                        <input
                            className="input__handler input__handler--decrement"
                            type="button"
                            value={"-"}
                            onClick={() => handleChannel(channel, -1)}
                            disabled={channel <= channelsMinQuantity}

                        />
                    </div>
                    <div className="input__display">
                        {channel}
                    </div>
                    <div className="input__handler">

                        <input
                            className="input__handler input__handler--increment"
                            type="button"
                            value={"+"}
                            onClick={() => handleChannel(channel, 1)}
                            disabled={channel >= channelsMaxQuantity}
                        />
                    </div>
                </div>
            </div>
            <div
                className="input--value"
                style={{
                    marginTop: 20,
                    marginbOTTOM: 20,
                    fontWeight: 500,
                }}
            >

                <div
                    style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                    }}
                >
                    A partir de
                </div>
                <div >
                    {!(Number(product) >= (sliderMaxQuantity[view] - 1) && Number(channel) >= channelsMaxQuantity) ?
                        <div style={{ margin: '15px 0px' }}>
                            <div style={{ marginBottom: 20 }}>
                                <Price price={finalResult} plus={false} />
                            </div>
                        </div>
                        :
                        <div style={{ margin: '15px 0px' }}>
                            <div style={{ marginBottom: 20 }}>
                                <Price price={finalResult} plus={true} />
                            </div>
                            <div style={{ fontSize: '16px', marginBottom: 20 }}>
                                Fale com um consultor para mais detalhes
                            </div>
                        </div>}
                </div>
            </div>
            <div className="input--contact">
                <button
                    style={{ width: '100%' }}
                    type="button"
                    title="Fale com um consultor"
                    className="btn btn--main"
                    onClick={openForm}
                >
                    Fale com um consultor
                </button>
            </div>
        </div >
    )
}

const Price = ({ price, plus }) => {
    return (
        <div>
            {minPrice < 199 ? `R$ 199` : `R$ ${price.toFixed(0)}${plus ? '+' : ``}`}
            <span style={{ fontSize: '1.2rem' }}>/mês</span>
        </div>
    )
}