import React from 'react';
import ContactForm from '../components/ContactForm';
import ChangePrices from '../components/MonitoramentoNetshoes/ChangePrices';
import MarketPlaceRules from '../components/MonitoramentoNetshoes/MarketplaceRules';
import NetshoesFAQ from '../components/MonitoramentoNetshoes/NetshoesFAQ';
import NetshoesMainSection from '../components/MonitoramentoNetshoes/NetshoesMainSection';
import NetshoesPlans from '../components/MonitoramentoNetshoes/NetshoesPlans';
import SKUMatching from '../components/MonitoramentoNetshoes/SKUMatching';
import YourCompetitors from '../components/MonitoramentoNetshoes/YourCompetitors';
import YourPerformance from '../components/MonitoramentoNetshoes/YourPerformance';

const MonitoramentoNetshoes = () => {
    return (
        <>
            <NetshoesMainSection />
            <SKUMatching />
            <MarketPlaceRules />
            <YourPerformance />
            <YourCompetitors />
            <ChangePrices />
            <NetshoesPlans />
            <NetshoesFAQ />
            <ContactForm formIdentifier='monitoramento-netshoes' bgColor='#fff' inputColor='#F0F5F8' />
        </>
    )
};

export default MonitoramentoNetshoes;
