import React, { useState } from 'react';
import FormBefore from './FormBefore';
import './ContactForm.scss';
import FormSuccess from './FormSuccess';

const ContactForm = ({ bgColor, inputColor, formId, formTitle, formSubtitle, formIdentifier }) => {

    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }

    return (
        <section className='contact-form-container' id={formId} style={{ backgroundColor: bgColor }}>
            {!isSubmitted ? (<FormBefore formIdentifier={formIdentifier} inputColor={inputColor} formTitle={formTitle} formSubtitle={formSubtitle} submitForm={submitForm} />) : (<FormSuccess />)}
        </section>
    )
}

export default ContactForm;
