import React, { useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { DropdownLoginItems } from './DropdownLoginItems';
import './dropdown.scss';

export function DropdownLogin() {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <>
            <ul onClick={handleClick} className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                {/* <span className='dropdown-info'>Ferramentas poderosas de análise de mercado para que você possa se posicionar estrategicamente.</span> */}
                {
                    /*
                    Nesse caso  não pode usar o Router, pq não vai ser possivel redirecionar para páginas fora do domínio
                    As paginas de login estão em outros dominios
                    Troquei o router por link normal
                    */
                }
                {DropdownLoginItems.map((item, index) => {
                    return (
                        <li key={index} className={item.spanClass}>
                            <a className={`${item.className} ${item.spanClass} `} href={item.path} onClick={() => setClick(false)}>
                                <i className={`${item.icon} ${item.spanClass} }`} /> <span className={item.spanClass}>{item.title}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

