import React from 'react';
import PricingPlansImg from '../../../assets/images/precificacaodinamica/pricing-plans.svg';
import PricingButton from '../../PricingButton';
import './PricingPlans.scss';

const PricingPlans = () => {
    return (
        <section className='pricing-plans-container'>
            <div className='pricing-plans-wrapper'>
                <div className='pricing-plans-image' data-aos="fade-up">
                    <img src={PricingPlansImg} />
                </div>
                <div className='pricing-plans-content'>
                    <div className='pricing-plans-heading'>
                        <h3>Confira nossos preços!</h3>
                    </div>
                    <div className='pricing-plans-text'>
                        <p>
                            Deseja conferir nossos preços e conversar com um de nossos especialistas para ver qual plano mais 
                            se adequa à sua demanda?
                        </p>
                    </div>
                    <PricingButton />
                </div>
            </div>
        </section>
    )
}

export default PricingPlans;
