import React from 'react';
import { ReactComponent as CategoriesImg } from '../../../assets/images/monitoramentoprecos/categories.svg';
import './Categories.scss';

const Categories = () => {
    return (
        <section className='categories-container'>
            <div className='categories-wrapper'>
                <div className='categories-content'>
                    <div className='categories-heading'>
                        <h3>Melhore as suas análises categorizando seus produtos</h3>
                    </div>
                    <div className='categories-text'>
                        <p>
                            Com a Hooklab você pode organizar e categorizar facilmente seus produtos usando tags personalizadas. Com essa funcionalidade, você pode marcar produtos com palavras-chave, tornando mais fácil encontrá-los e filtrá-los no seu catálogo de produtos.
                        </p>
                    </div>
                </div>
                <div className='categories-image' data-aos="fade-left">
                    <CategoriesImg />
                </div>
            </div>
        </section>
    )
}

export default Categories;
