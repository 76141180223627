import React from 'react';
import {ReactComponent as ChangePricesImg} from '../../../assets/images/monitoramentonetshoes/change-prices.svg';
import './ChangePrices.scss';

const ChangePrices = () => {
    return (
        <section className='change-prices-container'>
            <div className='change-prices-wrapper'>
                <div className='change-prices-image' data-aos="fade-right">
                    <ChangePricesImg />
                </div>
                <div className='change-prices-content'>
                    <div className='change-prices-heading'>
                        <h3>Altere preços em massa</h3>
                    </div>
                    <div className='change-prices-text'>
                        <p>
                            Altere e otimize seus preços com a Hooklab. Fornecemos todas as informações que você precisa 
                            para precificar seus produtos e sair na frente dos seus concorrentes dentro desse marketplace.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChangePrices;
