import React from 'react';
import './SKUMatching.scss';
import Netshoes3 from '../../../assets/images/monitoramentonetshoes/netshoes3.svg';
import Netshoes4 from '../../../assets/images/monitoramentonetshoes/netshoes4.svg';
import ProblemsButton from '../../ProblemsButton';

const SKUMatching = () => {
    return (
        <section className='sku-matching-container'>
            <div className='sku-matching-wrapper'>
                <div className='sku-matching-wrapper'>
                    <div className='sku-matching-heading'>
                        <h3>Não é só catalogar seus produtos e vender</h3>
                    </div>
                    <div className='sku-matching-images'>
                        <img className='sku-matching-image netshoes-three ' src={Netshoes3} data-aos='fade-left'/>
                        <img className='sku-matching-image netshoes-four' src={Netshoes4} data-aos='fade-right'/>
                    </div>
                    <div className='sku-matching-text'>
                        <p>
                            A Netshoes tem um sistema de match de SKUs, isso quer dizer que o mesmo produto pode ser vendido por vários 
                            sellers, e esses sellers disputam entre si para conseguir o buy-box.
                        </p>
                    </div>
                    <ProblemsButton scrollTo='marketplace-rules' />
                </div>
            </div>
        </section>
    )
}

export default SKUMatching;
