export default function FlipCard({ title, description, icon }) {

    return (
        <div class="flip-card">
            <div className="flip-card--header">
                {icon}
                {title}
            </div>
            <div className="flip-card--content">
                {description}
            </div>
        </div>


    )
}