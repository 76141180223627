import React from 'react';
import {ReactComponent as YourPerformanceImg} from '../../../assets/images/monitoramentonetshoes/your-performance.svg';
import './YourPerformance.scss';

const YourPerformance = () => {
    return (
        <section className='your-performance-container'>
            <div className='your-performance-wrapper'>
                <div className='your-performance-image' data-aos="fade-right">
                    <YourPerformanceImg />
                </div>
                <div className='your-performance-content'>
                    <div className='your-performance-heading'>
                        <h3>Tenha noção da sua performance no marketplace</h3>
                    </div>
                    <div className='your-performance-text'>
                        <p>
                            A Netshoes tem prioridade sobre vários SKUs, 
                            isso quer dizer que a escolha de produtos nesse canal é fundamental para não comprometer seu estoque 
                            com produtos que terão pouca saída.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default YourPerformance;
