import axios from 'axios'
import React, { useState } from 'react'

import './fill-email.styles.scss'

export default function FillEmail({ setViewModal, setViewFillEmail, selectedProduct, setSelectedProduct }) {

    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const validateMail = async () => {
        try {
            const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
            setError('');
            if (!regex.test(email)) return setError('Por favor, informe o seu endereço de e-mail para assistir a demonstração')

            await saveMail()
            setViewModal(true);
            setViewFillEmail(false);
        

        } catch (error) {
            setError('Parece que ocorreu um erro ao processar a sua solicitação.')
        }

    }

    const saveMail = async () => {
        await axios.post(`https://www.rdstation.com.br/api/1.3/form-integrations`,
            {
                email,
                selectedProduct,
                token_rdstation: "7f09339e93ab595d0fcbb0495c57608a",
                form_url: "https://hooklab.com.br/",
                page_title: "Hooklab",
                identificador: `Assistiu a Demonstração - ${selectedProduct}`
            }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    return (
        <div className='fill-email'>
            <h4>Preencha o seu endereço de e-mail para assistir à demonstração</h4>
            <div className='field'>
                <input value={email} onChange={e => setEmail(e.target.value)} type='text' placeholder='seuendereco@email.com' />
                <button className='blue-btn' onClick={validateMail}>Assistir</button>
            </div>
            {error && <div className='error'>{error}</div>}
            <div>
                Ao informar o seu e-mail, você está de acordo com a nossa <a target='_blank' href='/privacidade'>política de privacidade</a>
            </div>
        </div>

    )
}
