import "./Netshoes.scss"
import { useLayoutEffect, useState } from "react"
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai"
import { Link } from "react-router-dom"
import ContactForm from "../../../ContactForm"

export default function Netshoes({ view, handleClose }) {

    const [active, setActive] = useState(false)

    useLayoutEffect(() => {
        view === "netshoes" ? setActive(true) : setActive(false)
        console.log(view.view);
        window.scrollBy(0, 0)
    }, [view])


    const openForm = () => {
        const form = document.querySelector('#form');
        form.scrollIntoView({
            behavior: 'smooth'
        })
    }

    return (
        active ?
            <section style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div id="solution" className="container container--solution">
                    <div className="solution-main-wrapper">
                        <div className="solutions">
                            <div className="heading-secondary heading-secondary--main">
                                <h3>Monitoramento Netshoes</h3>

                            </div>
                            <div className="heading-secondary--sub">
                                <p>Monitore o preço dos seus concorrentes e melhore o seu posicionamento no mercado</p>
                            </div>

                            <div className="solutions-wrapper">
                                <div className="solution solution--2 solution-netshoes">
                                    <div className="solution__heading">
                                        <h4 className="solution__heading--title">Plano Starter</h4>
                                    </div>
                                    <div className="solution__boding">

                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>
                                            <div>
                                                <h4 className="heading-tertiary--main">Monitoramento Básico</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">Até 400 SKUs, 3 leituras ao dia.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>
                                            <div>
                                                <h4 className="heading-tertiary--main">Indicadores de Performance</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">Situação do produto.</p>
                                                    </li>
                                                    <li>
                                                        <p className="heading-tertiary--sub">Potencial de otimização, redução de preços e ganhos.</p>
                                                    </li>
                                                    <li>
                                                        <p className="heading-tertiary--sub">Competitividade.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>
                                            <div>
                                                <h4 className="heading-tertiary--main">Relatórios</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">Marca, competitividade, produtos novos e histórico de preços.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>
                                            <div>
                                                <h4 className="heading-tertiary--main">Sincronização</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">Através de planilha do painel Netshoes.</p>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="solution__boding--row solution-price">
                                            <h4 className="heading-tertiary--main">
                                                a partir de
                                            </h4>
                                            <h4 className="heading-tertiary--main">
                                                <span>R$ 89,99</span>/mês
                                            </h4>
                                        </div>
                                        <div
                                            className="solution__boding--row solution__boding--row--center">
                                            <Link
                                                to='/solucoes/monitoramento-netshoes'
                                                className='btn--link solution__boding--row--center__button'
                                            >
                                                <AiOutlinePlus />
                                                Saiba mais sobre a solução
                                            </Link>
                                        </div>
                                        {/* <InputLenght 
                                    skuPrice={3} 
                                    channelPrice={2.40}
                                    title='Para quantos produtos do seu catálogo, você deseja monitorar concorrentes?' 
                                    view={view}
                                /> */}
                                        <div
                                            className="solution__boding--row">

                                            <div className="input--contact">
                                                <button
                                                    onClick={openForm}
                                                    style={{ width: '100%' }}
                                                    type="button"
                                                    title="Fale com um consultor"
                                                    className="btn btn--main"
                                                >
                                                    Fale com um consultor
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="btn--link-wrapper"
                                        >
                                            <button
                                                className='btn--link'
                                                onClick={handleClose}
                                            >
                                                Escolher outra solução
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="solution solution--2 solution-netshoes">
                                    <div className="solution__heading">
                                        <h4 className="solution__heading--title">Plano Performance</h4>
                                    </div>
                                    <div className="solution__boding">

                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>
                                            <div>
                                                <h4 className="heading-tertiary--main">Monitoramento Básico</h4>
                                                <p className="heading-tertiary--sub">Até 400 SKUs, a cada 2 hora</p>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>
                                            <div>
                                                <h4 className="heading-tertiary--main">Indicadores de Performance</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">- Situação do produto.</p></li>
                                                    <li><p className="heading-tertiary--sub">- Potencial de otimização, redução de preços e ganhos.</p></li>
                                                    <li><p className="heading-tertiary--sub">- Competitividade.</p></li>
                                                </ul>
                                                <p className="heading-tertiary--sub">

                                                </p>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>

                                            <div>
                                                <h4 className="heading-tertiary--main">Inteligência Competitiva</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">- Monitoramento de sellers no buy-box.</p></li>
                                                    <li><p className="heading-tertiary--sub">- Preço e estoque de concorrentes.</p></li>
                                                    <li><p className="heading-tertiary--sub">- Sugestão de Preços.</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>

                                            <div>
                                                <h4 className="heading-tertiary--main">Relatórios</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">- Marca, competitividade, produtos novos e histórico de preços.</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>

                                            <div>
                                                <h4 className="heading-tertiary--main">Sincronização</h4>
                                                <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                        <p className="heading-tertiary--sub">- Através de planilha do painel Netshoes.</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row">
                                            <div className={"solution__boding--row--icon"}>
                                                <AiOutlineCheck />
                                            </div>
                                            <div>
                                                <h4 className="heading-tertiary--main">Segmentação por tags e filtros</h4>
                                                <p className="heading-tertiary--sub">Você poderá segmetar os seus produtos e o produtos dos seus concorrentes utilizando tags e filtros.</p>
                                            </div>
                                        </div>
                                        <div className="solution__boding--row solution-price">
                                            <h4 className="heading-tertiary--main">
                                                a partir de
                                            </h4>
                                            <h4 className="heading-tertiary--main">
                                                <span>R$ 159,99</span>/mês
                                            </h4>

                                        </div>
                                        <div
                                            className="solution__boding--row solution__boding--row--center">
                                            <Link
                                                to='/solucoes/monitoramento-netshoes'
                                                className='btn--link solution__boding--row--center__button'
                                            >
                                                <AiOutlinePlus />
                                                Saiba mais sobre a solução
                                            </Link>
                                        </div>
                                        {/* <InputLenght 
                                    skuPrice={3} 
                                    channelPrice={2.40}
                                    title='Para quantos produtos do seu catálogo, você deseja monitorar concorrentes?' 
                                    view={view}
                                /> */}

                                        <div
                                            className="solution__boding--row">

                                            <div className="input--contact">
                                                <button
                                                    style={{ width: '100%' }}
                                                    type="button"
                                                    title="Fale com um consultor"
                                                    className="btn btn--main"
                                                    onClick={openForm}
                                                >
                                                    Fale com um consultor
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="btn--link-wrapper"
                                        >
                                            <button
                                                className='btn--link'
                                                onClick={handleClose}
                                            >
                                                Escolher outra solução
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ContactForm formTitle='Fale com um dos nossos consultores' formIdentifier='planos-monitoramento-netshoes' bgColor='#fff' inputColor='#F0F5F8' />
                        </div>
                    </div>
                </div>
            </section>
            : null
    )
}