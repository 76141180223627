import React, { useState } from 'react';
import { Questions } from './FAQData';
import './MonitoringFAQ.scss';

const MonitoringFAQ = () => {

    const [clicked, setClicked] = useState(false);

    const toggle = index => {
        if (clicked === index) {
            return setClicked(null)
        }
        setClicked(index)
    }

    return (
        <section className='monitoring-faq-container'>
            <div className='monitoring-faq-wrapper'>
                <div className='monitoring-faq-heading'>
                    <h3>Perguntas Frequentes</h3>
                </div>
                <div className='faq-area'>
                    {Questions.map((item, index) => {
                        return (
                            <>
                                <div className='question-wrap price-question' onClick={() => toggle(index)} key={index}>
                                    <h4>{item.question}</h4>
                                    <i className={clicked === index ? 'fas fa-minus' : 'fas fa-plus'} />
                                </div>
                                {clicked === index ? (
                                    <div className='dropdown-answer'>
                                        {item.answer.map((a) => {
                                            return (
                                                <>
                                                    <p>{a}</p>
                                                    <br />
                                                </>
                                            )
                                        })}

                                    </div>
                                ) : null}
                            </>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default MonitoringFAQ;
