import React from 'react';
import './ContactForm.scss';
import SuccessImage from '../../assets/images/form-success.svg';

const FormSuccess = () => {
    return (
        <div className='success-message-wrapper'>
            <div className='success-message-image'>
                <img src={SuccessImage} />
            </div>
            <div className='success-message-text'>
                <p>
                    Sua mensagem foi enviada e logo entraremos em contato!
                </p>
            </div>
        </div>
    )
}

export default FormSuccess;
