import React from 'react';
import SafetyFirstImg from '../../../assets/images/precificacaodinamica/safety-first.svg';
import './SafetyFirst.scss';

const SafetyFirst = () => {
    return (
        <section className='safety-first-container'>
            <div className='safety-first-wrapper'>
                <div className='safety-first-content'>
                    <div className='safety-first-heading'>
                        <h3>Precificação segura e precisa: garanta sucesso no mercado</h3>
                    </div>
                    <div className='safety-first-text'>
                        <p>
                            Com a nossa ferramenta para simulação de precificação, você pode visualizar as alterações que serão realizadas imediatamente, antes de implementá-las. Além disso, trabalhamos com travas de preço mínimo e máximo, garantindo que sua estratégia de precificação seja sempre lucrativa.
                        </p>
                    </div>
                </div>
                <div className='safety-first-image' data-aos="fade-left">
                    <img src={SafetyFirstImg} />
                </div>
            </div>
        </section>
    )
}

export default SafetyFirst;
