import React from 'react';
import './button.scss';
import { Link as LinkRoute } from 'react-router-dom';

export function Button() {
  return (
    <LinkRoute to='/demonstracao'>
      <button className='btn btn--demo'>Demonstração gratuita</button>
    </LinkRoute>
  );
}
