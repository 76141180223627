import React from 'react';
import CustomerViewImg from '../../../assets/images/precificacaodinamica/customer-view.svg';
import './CustomerView.scss';

const CustomerView = () => {
    return (
        <section className='customer-view-container'>
            <div className='customer-view-wrapper'>
                <div className='customer-view-image' data-aos="fade-right">
                    <img src={CustomerViewImg} alt='Customer view' />
                </div>
                <div className='customer-view-content'>
                    <div className='customer-view-heading'>
                        <h3>Aumente a competitividade de sua empresa com a precificação dinâmica da Hooklab</h3>
                    </div>
                    <div className='customer-view-text'>
                        <p>
                            Com a precificação dinâmica da Hooklab, sua empresa estará sempre competitiva no mercado. Nós analisamos e ajustamos os seus preços em relação aos seus concorrentes, garantindo que as sua ofertas sejam preferenciais pelos consumidores.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerView;
