import React from 'react';
import './Problems.scss';
import {ReactComponent as Problems1} from '../../../assets/images/monitoramentoprecos/problems-1.svg';
import {ReactComponent as Problems2} from '../../../assets/images/monitoramentoprecos/problems-2.svg';
import {ReactComponent as Problems3} from '../../../assets/images/monitoramentoprecos/problems-3.svg';
import ProblemsButton from '../../ProblemsButton';

const Problems = () => {
    return (
        <>
            <section className='problems-container'>
                <div className='problems-wrapper'>
                    <div className='problems-heading'>
                        <h3>Você lida com alguma dessas situações?</h3>
                    </div>
                    <div className='problems-items-wrapper'>
                        <div className='problems-items'>
                            <div className='problems-image' data-aos="fade-down">
                                <Problems1 className='problems-items-img' />
                            </div>
                            <div className='problems-items-text' data-aos="fade-up">
                                <p>
                                    Monitoro os preços dos meus concorrentes via planilhas,  Buscapé ou Zoom
                                </p>
                            </div>    
                        </div>
                        <div className='problems-items'>
                            <div className='problems-image' data-aos="fade-down">
                                <Problems2 className='problems-items-img' />
                            </div>
                            <div className='problems-items-text' data-aos="fade-up">
                                <p>
                                    Não sei se meus preços são competitivos em relação ao mercado
                                </p>
                            </div>    
                        </div>
                        <div className='problems-items'>
                            <div className='problems-image' data-aos="fade-down">
                                <Problems3  className='problems-items-img' />
                            </div>
                            <div className='problems-items-text' data-aos="fade-up">
                                <p>
                                    Quero melhorar meu posicionamento de preços em relação aos concorrentes
                                </p>
                            </div>    
                        </div>
                    </div>
                    <ProblemsButton scrollTo='market-monitoring' />
                </div>
            </section>
        </>
    )
}

export default Problems;
