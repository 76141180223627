import React, { useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { DropdownItems } from './DropdownItems';
import './dropdown.scss';

export function Dropdown() {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <>
            <ul onClick={handleClick} className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                {/* <span className='dropdown-info'>Ferramentas poderosas de análise de mercado para que você possa se posicionar estrategicamente.</span> */}
                {DropdownItems.map((item, index) => {
                    return (
                        <li key={index}>
                            <LinkRouter className={item.className} to={item.path} onClick={() => setClick(false)}>
                                <i className={item.icon} /> <span>{item.title}</span>
                            </LinkRouter>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

