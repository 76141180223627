import React from 'react';
import Netshoes1 from '../../../assets/images/monitoramentonetshoes/netshoes1.svg';
import Netshoes2 from '../../../assets/images/monitoramentonetshoes/netshoes2.svg';
import MktplaceRules1 from '../../../assets/images/monitoramentonetshoes/marketplace-rules-1.svg';
import MktplaceRules2 from '../../../assets/images/monitoramentonetshoes/marketplace-rules-2.svg';
import './MarketplaceRules.scss';

const MarketPlaceRules = () => {
    return (
        <section className='marketplace-rules-container' id='marketplace-rules'>
            <div className='marketplace-rules-wrapper'>
                <div className='marketplace-rules-heading'>
                    <h3>A Netshoes é um marketplace grande e de nicho, com regras bem específicas que os sellers precisam praticar</h3>
                </div>
                <div className='marketplace-rules-images'>
                    <div className='rules-row-one'>
                        <img src={Netshoes1} className='rules-png' data-aos='fade-left' />
                        <img src={MktplaceRules1} className='rules-svg marketplace-rules-girl' data-aos='fade-left' delay='2000' />
                    </div>
                    <div className='rules-row-two'>
                        <img className='rules-svg marketplace-rules-boy' src={MktplaceRules2} data-aos='fade-right' delay='2000' />
                        <img src={Netshoes2} className='rules-png' data-aos='fade-right' />
                    </div>
                </div>
                <div className='marketplace-rules-text'>
                    <p>
                        Apenas catalogar diversos produtos e receber a confirmação da Netshoes de que eles estão disponíveis, não quer dizer que eles serão vendidos. O buy-box na Netshoes é definido principalmente pelo menor preço ofertado pelos lojistas. Por isso, você deve se posicionar estrategicamente e nós podemos te ajudar nisso.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default MarketPlaceRules;
