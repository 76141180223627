import React from 'react';
import BlogOverview from '../components/Home/BlogOverview';
import Cases from '../components/Home/Cases';
import MainSection from '../components/Home/MainSection';
import CustomSolutionsOverview from '../components/Home/ProductsOverview/CustomSolutionsOverview';
import DynamicPricingOverview from '../components/Home/ProductsOverview/DynamicPricingOverview';
import NetshoesOverview from '../components/Home/ProductsOverview/NetshoesOverview';
import PriceMonitoringOverview from '../components/Home/ProductsOverview/PriceMonitoringOverview';
import ResellerMonitoringOverview from '../components/Home/ProductsOverview/ResellerMonitoringOverview';
import SupportTeam from '../components/Home/SupportTeam';

const Home = () => {

    return (
        <>
            <MainSection />
            <Cases />
            <PriceMonitoringOverview />
            <ResellerMonitoringOverview />
            <NetshoesOverview />
            <DynamicPricingOverview />
            <CustomSolutionsOverview />
            <SupportTeam />
            <BlogOverview />
        </>
    );
};

export default Home;
