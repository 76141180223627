import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { solutions } from "./solutions";
import Solution from "./Solution";
import 'react-vertical-timeline-component/style.min.css';

export default function TimeLine() {

    return (
        <div style={{ overflow: 'hidden' }} >
            <div className="heading-secondary heading-secondary--main">
                <h3>Linha do tempo</h3>
            </div>
            <VerticalTimeline
                layout='1-column-left'
                lineColor={"#2196F3"}
            >
                {solutions.map((solution, index) => {
                    return (
                        <VerticalTimelineElement
                            iconStyle={{ backgroundColor: 'rgb(33, 150, 243)', color: '#FFF' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            contentStyle={{ border: '0px', boxShadow: '0px 0px 0px 0px', paddingTop: 20 }}
                            style={{ overflow: 'hidden' }}
                            dateClassName="date"
                            iconClassName="icon"
                            icon={solution.icon}
                            position='top'
                        >
                            <Solution
                                solution={solution}
                                index={index}
                            />
                        </VerticalTimelineElement>
                    )
                })}
            </VerticalTimeline>
        </div>
    )
}