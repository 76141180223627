import React from 'react';
import './MainSection.scss';
import {ReactComponent as MainImg} from '../../../assets/images/monitoramentoprecos/img1.svg';
import Typewriter from 'typewriter-effect';


const MainSection = () => {
    return (
        <>
            <section className='monitoring-container'>
                <div className='monitoring-wrapper'>
                    <div className='monitoring-text'>
                        <div className='monitoring-heading'>
                            <div className='static-text'>
                                <h1>Monitoramento de Concorrentes</h1>
                            </div>
                            <div className='dynamic-texts'>
                                <Typewriter
                                options={{
                                strings: ['Monitoramos sites', 'Reunimos dados', 'Fornecemos informações'],
                                autoStart: true,
                                loop: true,
                                }}
                        />
                            </div>
                        </div>
                        <div className='monitoring-subheading'>
                            <p>
                                Nunca mais perca tempo em planilhas cheias de links e preços. Monitoramos os sites que você precisar, 
                                reunimos todos os dados e  transformamos em informações quentes  para suas análises.
                            </p>
                        </div> 
                    </div>
                    <div>
                        <MainImg className='monitoring-img' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default MainSection;
