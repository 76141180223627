import React from 'react';
import { ReactComponent as SupportTeamImg } from '../../../assets/images/homeimgs/support-team.svg';
import './SupportTeam.scss';

const SupportTeam = () => {
    return (
        <section className='support-team-container'>
            <div className='support-team-wrapper'>
                <div data-aos='fade-up'>
                    <SupportTeamImg className='support-team-image' />
                </div>
                <div className='support-team-heading'>
                    <h3>Precisa de ajuda?</h3>
                </div>
                <div className='support-team-text'>
                    <p>
                        Nosso time de Customer Success consegue te ajudar
                        com rapidez no atendimento através do nosso chat localizado na plataforma ou então pelo WhatsApp. <b>Nada de ligações demoradas, tickets e e-mails sem respostas.</b>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default SupportTeam;
