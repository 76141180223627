import React from 'react';
import { ReactComponent as PricingRulesImg } from '../../../assets/images/precificacaodinamica/creating-pricing-rules.svg';
import CarouselPricingRule from './Carousel';
import './PricingRules.scss';

const PricingRules = () => {
    return (
        <section className='pricing-rules-container' id='pricing-rules'>
            <div className='pricing-rules-wrapper'>
                <div className='pricing-rules-heading'>
                    <h3>Você define como quer precificar seus produtos</h3>
                </div>
                <div className='pricing-rules-text'>
                    <p>
                        Na Hooklab as regras de precificação são definidas por você, lhe damos várias opções e você escolhe a melhor
                        estratégia. Tudo customizável.
                    </p>
                </div>
                <div className='pricing-rules-image' data-aos="fade-up">
                    <CarouselPricingRule />
                </div>

            </div>
        </section>
    )
}

export default PricingRules;
