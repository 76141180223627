import React from 'react';
import { ReactComponent as TransparencyImg } from '../../../assets/images/precificacaodinamica/transparency.svg';
import './Transparency.scss';

const Transparency = () => {
    return (
        <section className='transparency-container'>
            <div className='transparency-wrapper'>
                <div className='transparency-content'>
                    <div className='transparency-heading'>
                        <h3>Transparência e Confiança</h3>
                    </div>
                    <div className='transparency-text'>
                        <p>
                            Disponibilizamos um histórico de preços detalhado com todas as alterações realizadas pela nossa plataforma, bem como a regra de precificação vigente. Com essa funcionalidade, você pode acompanhar o trabalho da Hooklab na otimização dos preços dos seus anúncios.
                        </p>
                    </div>
                </div>
                <div className='transparency-image' data-aos="fade-left">
                    <TransparencyImg />
                </div>
            </div>
        </section>
    )
}

export default Transparency;
