import "./ResellerMonitoring.scss"
import InputLenght from "../InputLenght/inputLength"
import { useLayoutEffect, useState } from "react"
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai"
import { Link } from "react-router-dom"
import ContactForm from "../../../ContactForm"

export default function ResellerMonitoring({ view, handleClose }) {

    const [active, setActive] = useState(false)

    useLayoutEffect(() => {
        view === "reseller" ? setActive(true) : setActive(false)
        console.log(view.view);
        window.scrollBy(0, 0)
    }, [view])

    return (
        active ?
            <section >
                <div id="solution" className="container container--solution">
                    <div className="solution-main-wrapper">
                        <div className="heading-secondary heading-secondary--main">
                            <h3>Monitoramento de Revendedores</h3>

                        </div>
                        <div className="heading-secondary--sub">
                            <p>Tenha controle sobre como os seus revendedores estão precificando</p>
                        </div>
                        <div className="solution solution--1">
                            <div className="solution__heading">
                                <h4 className="solution__heading--title">Benefícios da Solução</h4>
                            </div>
                            <div className="solution__boding">
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Monitoramento de preços de revendedores</h4>
                                        <p className="heading-tertiary--sub">Você poderá monitorar os anúncios dos seus revendedores e verificar se eles estão compativeis com a sua política comercial</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Matching de Produtos</h4>
                                        <p className="heading-tertiary--sub">A Hooklab irá encontrar os produtos de revendedores que estão sendo ofertados em marketplaces e e-commerces que você escolher</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Histórico de Preços</h4>
                                        <p className="heading-tertiary--sub">Você terá acesso ao histórico de todas ofertas que estiverem sendo monitoradas</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Visualização de Empresas</h4>
                                        <p className="heading-tertiary--sub">Você poderá agrupar todos as ofertas que um revendedor possui em diferentes canais em um único lugar.</p>
                                    </div>
                                </div>
                                <div className="solution__boding--row">
                                    <div className={"solution__boding--row--icon"}>
                                        <AiOutlineCheck />
                                    </div>
                                    <div>
                                        <h4 className="heading-tertiary--main">Notificações Automáticas</h4>
                                        <p className="heading-tertiary--sub">Você poderá configurar notificações que serão enviadas por e-mail periodicamente para as empresas que estiverem descumprindo a sua política comercial.</p>
                                    </div>
                                </div>

                                <div
                                    className="solution__boding--row solution__boding--row--center">
                                    <Link
                                        to='/solucoes/monitoramento-de-revendedores'
                                        className='btn--link solution__boding--row--center__button'
                                    >
                                        <AiOutlinePlus />
                                        Saiba mais sobre a solução
                                    </Link>
                                </div>
                                <InputLenght
                                    view={view}
                                    skuPrice={4}
                                    channelPrice={2.4}
                                    title='Para quantos produtos do seu catálogo, você deseja monitorar revendedores?' />
                                <div
                                    className="btn--link-wrapper"
                                >
                                    <button
                                        className='btn--link'
                                        onClick={handleClose}
                                    >
                                        Escolher outra solução
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactForm formTitle='Fale com um dos nossos consultores' formIdentifier='planos-monitoramento-revendedores' bgColor='#fff' inputColor='#F0F5F8' />

            </section>
            : null
    )
}