import React, { useEffect } from 'react'

import './video-modal.styles.scss'

export default function VideoModal({ view, setView, videoURL }) {

    useEffect(() => {
        document.querySelector('body').style.overflowY = 'hidden'
        return () => {
            document.querySelector('body').style.overflowY = 'auto'
        }
    }, [])

    return (
        <>
            <div className='video-modal' style={!view ? { opacity: 0, zIndex: -1 } : { opacity: 1 }}>
                <iframe
                    width="100%"
                    height="100%"
                    src={videoURL}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; modestbranding=1; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
            <div style={!view ? { opacity: 0, zIndex: -1 } : { opacity: 1 }} className='video-modal-overlay' onClick={() => setView(false)}></div>
        </>
    )
}
