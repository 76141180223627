import { useState } from "react";
import { IoClose } from "react-icons/io5"
import DynamicPrecification from "./DynamicPrecification";
import ResellerMonitoring from "./ResellerMonitoring";
import Monitoring from "./Monitoring";
import Netshoes from "./Netshoes";
import { ReactComponent as MonitoringSVG } from "../../../assets/images/planos/monitoring.svg";
import { ReactComponent as ResellerSVG } from "../../../assets/images/planos/reseller.svg";
import { ReactComponent as PrecificationSVG } from "../../../assets/images/planos/precification.svg";
import { ReactComponent as NetshoesSVG } from "../../../assets/images/planos/netshoes.svg";
import "./GuidedPlans.scss";


export default function GuidadePlans() {

    const [view, setView] = useState(false)

    function handleView(e) {
        setTimeout(() => setView(e), 400)
        const guia = document.getElementById("guia")
        guia.classList.remove("showInUp")
        guia.classList.add("isUping")
        window.scrollTo(0, 0);

        return
    }

    function handleClose() {

        const closing = document.getElementById("solution")
        setTimeout(() => {
            const guia = document.getElementById("guia")
            guia.classList.add("showInUp")
            window.scrollTo(0, 0)
        }, 101)

        closing.classList.add('hideBottom')
        setTimeout(() => {
            setView(false)
        }, 100)

    }

    return (
        <>
            {view
                ? <>
                    {
                        view === "monitoring"
                            ? <Monitoring
                                view={view}
                                handleClose={handleClose}
                            />
                            : null
                    }
                    {
                        view === "reseller"
                            ? <ResellerMonitoring
                                view={view}
                                handleClose={handleClose}
                            />
                            : null
                    }
                    {
                        view === "dynamic"
                            ? <DynamicPrecification
                                view={view}
                                handleClose={handleClose}
                            />
                            : null
                    }
                    {
                        view === "netshoes"
                            ? <Netshoes
                                view={view}
                                handleClose={handleClose}
                            />
                            : null
                    }
                    <div className="solution-close">
                        <IoClose
                            color="black"
                            className="solution-close__icon"
                            onClick={() => handleClose()}
                        />
                    </div>
                </>
                : <section id="guia" className="container--guided ">
                    <div className="guided-main-wrapper">
                        <div className="heading-secondary heading-secondary--main">
                            <h3>
                                Qual solução você deseja utilizar?
                            </h3>
                        </div>
                        <div className="heading-secondary--sub u-margin-bottom-small">
                            <p>Vamos encontrar o plano que melhor se encaixa com a sua demanda</p>
                        </div>
                        <div className="question">
                            <div
                                className="box"
                                onClick={() => handleView("monitoring")}
                            >
                                <p className="box--paragraph">Eu desejo monitorar meus concorrentes</p>
                                <MonitoringSVG className="box--icon" />
                            </div>
                            <div
                                className="box"
                                onClick={() => handleView("reseller")}
                            >
                                <p className="box--paragraph">
                                    Eu sou uma marca e desejo monitorar meus revendedores
                                </p>
                                <ResellerSVG className="box--icon" />
                            </div>
                            <div
                                className="box"
                                onClick={() => handleView("dynamic")}
                            >
                                <p className="box--paragraph">
                                    Eu desejo automatizar a minha precificação em e-commerce ou marketplaces
                                </p>
                                <PrecificationSVG className="box--icon" />
                            </div>
                            <div
                                className="box"
                                onClick={() => handleView("netshoes")}
                            >
                                <p className="box--paragraph">
                                    Vendo produtos na Netshoes e desejo dominar o Buy-box
                                </p>
                                <NetshoesSVG height={"50px"} className="box--icon" />
                            </div>
                        </div>
                    </div>
                </section>}
        </>
    )
}