import React from 'react'
import VideoModal from '../../Demonstracao/VideoModal'
import './MonitoringVideo.styles.scss'

export default function Video() {
    return (
        <section className='monitoring-prices-video-container'>
            <h3>Conheça a solução</h3>
            <iframe
                src="https://www.youtube.com/embed/Mh7tWtGCpz4?controls=1&modestbranding=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; modestbranding=1; autoplay=0; controls=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe>

        </section>
    )
}
